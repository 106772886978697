<template>
  <div>
    <div class="card height-auto v-application v-application--is-ltr">
      <!-- <div class="add-icon text-center" >
        {{ items.length }}
        <v-icon large class="main-color">add_circle</v-icon>
      </div>-->

      <div class="card-body">
        <v-data-table
          v-can="table.permissions.list"
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="table.headers"
          :items="items"
          item-key="id"
          :show-select="
            table.modal == 'All Exam Results' ||
            table.modal == 'ApplicantLogs' ||
            table.modal == 'Fee Plan' ||
            table.modal == 'Tax' ||
            table.modal == 'Cash Point' ||
            table.modal == 'Subject' ||
            table.modal == 'Subject Type' ||
            table.modal == 'Class' ||
            table.modal == 'Book' ||
            table.modal == 'Grade' ||
            table.modal == 'Qualifier' ||
            table.modal == 'Teacher' ||
            table.modal == 'Library' ||
            table.modal == 'Discussion Room' ||
            table.modal == 'Warehouse Transactin' ||
            table.modal == 'Warehouse Item' ||
            table.modal == 'DiscountPackage' ||
            table.modal == 'BankReceipt' ||
            table.modal == 'All Exam Results for parentDashboard'
              ? false
              : true
          "
          class="elevation-1 level1"
          hide-default-footer
          :search="table.modal == 'ApplicantLogs' ? search : no_search"
          :disable-pagination="table.modal == 'All Exam Results' ? true : false"
        >
          <template v-slot:top>
            <div class="text-center" v-if="table.modal == 'Books List'">
              <p v-if="filter.booksListGrade == ''" class="main-color">
                {{ $t("No grade is selected") }}
              </p>
            </div>
            <!-- <v-row
              v-if="
                table.modal == 'All Exam Results for parentDashboard' &&
                  childs.length > 1
              "
            >
              <v-col cols="4" sm="4" offset-sm="8" offset="8">
                <v-select
                  :items="childs"
                  item-text="name"
                  item-value="id"
                  v-model.trim="selectedChild"
                  solo
                ></v-select>
              </v-col>
            </v-row> -->
            <v-row class="headerTable-div">
              <v-col cols="6" md="4" sm="12">
                <h3
                  v-if="table.modal == 'Books List'"
                  style="margin-top: 15px"
                  class="custom-header main-color"
                >
                  {{ table.title }}
                </h3>
                <h3 v-else class="custom-header main-color">
                  {{ table.title }}
                </h3>
              </v-col>
              <v-col cols="6" md="3" sm="12">
                <label
                  class="search"
                  for="inpt_search"
                  v-if="table.modal != 'DiscountPackage'"
                >
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    @keyup.enter="getDataFromApi(page, true)"
                    v-model="search"
                  />
                  <v-icon
                    :title="$t('Search')"
                    @click="
                      table.modal != 'ApplicantLogs'
                        ? getDataFromApi(page, true)
                        : ''
                    "
                    class="search-icon"
                    >search</v-icon
                  >
                </label>

                <!--=============== Students Books List Filter ==============-->
                <span v-if="table.modal == 'Books List'" :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Classes')"
                          :items="classesInGrade"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          v-model="filter.classes"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>

                <!-- Cach Point -->
                <span v-if="table.modal == 'Cash Point'" :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Type')"
                          :items="[
                            { id: 'Bank', text: $t('Bank') },
                            { id: 'Cash', text: $t('Cash') },
                          ]"
                          @change="hideLabel = true"
                          v-model="filter.type"
                          multiple
                          solo
                          item-text="text"
                          item-value="id"
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Payment Method')"
                          :items="[
                            { id: 'Transfer', text: $t('Transfer') },
                            { id: 'POS', text: $t('POS') },
                            {
                              id: 'Online Payment',
                              text: $t('Online Payment'),
                            },
                            { id: 'Check', text: $t('Check') },
                            { id: 'Cash', text: $t('Cash') },
                          ]"
                          @change="hideLabel = true"
                          v-model="filter.payment_method"
                          solo
                          item-value="id"
                          item-text="text"
                        ></v-autocomplete>
                      </v-list-item>
                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>

                <!-- Item -->
                <span v-if="table.modal == 'Item'" :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Type')"
                          :items="[
                            { title: $t('Product'), value: 'product' },
                            { title: $t('Service'), value: 'service' },
                          ]"
                          item-text="title"
                          item-value="value"
                          @change="hideLabel = true"
                          v-model="filter.type"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Category')"
                          :items="itemsCategory"
                          item-text="name"
                          item-value="id"
                          @change="hideLabel = true"
                          v-model="filter.category_id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
                <!-- Partner -->
                <span v-if="table.modal == 'Partner'" :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Type')"
                          :items="[
                            { id: 'Company', text: $t('Company') },
                            { id: 'Individual', text: $t('Individual') },
                          ]"
                          @change="hideLabel = true"
                          v-model="filter.type"
                          multiple
                          solo
                          item-text="text"
                          item-value="id"
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Partner Group')"
                          :items="partnerGrops"
                          item-text="name"
                          item-value="id"
                          @change="hideLabel = true"
                          v-model="filter.group_ids"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
              </v-col>
              <v-col md="2" sm="12" v-if="table.modal == 'Warehouse Item'">
                <v-checkbox
                  v-if="table.modal == 'Warehouse Item'"
                  :label="$t('Show Zero')"
                  v-model="NotShowZero"
                  @change="GetItemsWithoutZero"
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col cols="6" md="3" sm="12" class="text-right">
                <!-- ==================================================filters================================================== -->

                <span v-if="table.modal == 'User'" :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Country')"
                          :items="nationalities"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          v-model="filter.countries"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Role')"
                          :items="roles"
                          @change="hideLabel = true"
                          item-text="display_name"
                          item-value="id"
                          multiple
                          v-model="filter.roles"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>

                <span
                  v-if="table.modal == 'All Exam Results for parentDashboard'"
                  :title="$t('Filter')"
                >
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <!-- <v-list-item class="select">
                        <v-select
                          :items="childs"
                          item-text="name"
                          item-value="id"
                          v-model.trim="selectedChild"
                          solo
                        ></v-select>
                      </v-list-item> -->
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Quarters')"
                          :items="allQuarters"
                          item-text="name"
                          item-value="id"
                          v-model="filter.quarters"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Qualifiers')"
                          :items="qualifierList"
                          @change="hideLabel = true"
                          item-text="qualifier_title"
                          item-value="qualifier_id"
                          multiple
                          v-model="filter.qualifiers_ids"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('subjects')"
                          :items="subjectsList"
                          @change="hideLabel = true"
                          item-text="subject_name"
                          item-value="subject_id"
                          multiple
                          v-model="filter.subject_ids"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <!-- <v-list-item>
                        <v-autocomplete
                          label="Subjects"
                          :items="subjectsList"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.subject_ids"
                          solo
                        ></v-autocomplete>
                      </v-list-item> -->

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
                <span
                  v-if="table.modal == 'All Exam Results'"
                  :title="$t('Filter')"
                >
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Quarters')"
                          :items="allQuarters"
                          item-text="name"
                          item-value="id"
                          v-model="filter.quarters"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Qualifiers')"
                          :items="qualifierList"
                          @change="hideLabel = true"
                          item-text="qualifier_title"
                          item-value="qualifier_id"
                          multiple
                          v-model="filter.qualifiers_ids"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <!-- <v-list-item>
                        <v-autocomplete
                          label="Academic Period ss"
                          :items="academicList"
                          @change="hideLabel = true"
                          item-text="academic_name"
                          item-value="academic_id"
                          multiple
                          v-model="filter.academic_periods_ids"
                          solo
                        ></v-autocomplete>
                      </v-list-item> -->
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Subjects')"
                          :items="subjectsList"
                          @change="hideLabel = true"
                          item-text="subject_name"
                          item-value="subject_id"
                          multiple
                          v-model="filter.subject_ids"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>

                <span v-if="table.modal == 'Exam'" :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Quarters')"
                          :items="allQuarters"
                          item-text="name"
                          item-value="id"
                          v-model="filter.quarters"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Subject')"
                          :items="table.filters.subjects"
                          @change="getQualifiersForFilter"
                          item-text="s_name"
                          item-value="id"
                          v-model="filter.subjects"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Qualifiers')"
                          :items="qualifiers"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.qualifiers"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Academic Period')"
                          :items="table.filters.academicPeriods"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.academic_periods"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
                <span v-if="table.modal == 'Teacher'" :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Gender')"
                          :items="table.filters.genders"
                          item-text="name"
                          item-value="id"
                          v-model="filter.genders"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Grades')"
                          :items="table.filters.grades"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.grades"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Subjects')"
                          :items="table.filters.subjects"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.subjects"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
                <span
                  v-if="table.modal == 'Library' || table.modal == 'My Library'"
                  :title="$t('Filter')"
                >
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Quarters')"
                          :items="allQuarters"
                          item-text="name"
                          item-value="id"
                          v-model="filter.quarter"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Type')"
                          :items="[
                            { id: 'file', name: $t('File') },
                            { id: 'url', name: $t('Url') },
                          ]"
                          item-text="name"
                          item-value="id"
                          v-model="filter.types"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Category')"
                          :items="table.filters.categories"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.categories"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <!-- Weeks -->
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Academic Weeks')"
                          :items="table.filters.academic_weeks_id"
                          @change="SelectWeek()"
                          item-text="name"
                          item-value="id"
                          v-model="filter.academic_weeks_id"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <!-- CheckBox Current Week -->
                      <v-list-item>
                        <v-checkbox
                          v-model="filter.currentWeek"
                          @change="CurrentWeek()"
                          :label="$t('Cuurent Week')"
                        ></v-checkbox>
                      </v-list-item>
                      <!-- v-if="canControlCategories" -->
                      <v-list-item v-if="!table.student">
                        <v-autocomplete
                          :label="$t('Grade')"
                          :items="table.filters.grades"
                          @change="getSubjectsUnderGrade"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.grades"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Subject')"
                          :items="table.filters.subjects"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.subjects"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
                <span v-if="table.modal == 'Invoice'" :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-if="!table.parent"
                          :label="$t('Status')"
                          :items="table.filters.status"
                          item-text="name"
                          item-value="id"
                          v-model="filter.status"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Payment Status')"
                          :items="[
                            { name: $t('Paid'), id: 1 },
                            { name: $t('Partially Paid'), id: 2 },
                            { name: $t('Not Paid'), id: 0 },
                          ]"
                          item-text="name"
                          item-value="id"
                          v-model="filter.payment_status"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Grade')"
                          :items="invoiceGrades"
                          item-text="name"
                          item-value="id"
                          v-model="filter.grade_ids"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Year')"
                          :items="years"
                          item-text="name"
                          item-value="id"
                          v-model="filter.year_id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>

                <span
                  v-if="
                    table.modal == 'Payment' || table.modal == 'GeneralPayment'
                  "
                  :title="$t('Filter')"
                >
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                        @click="InitializeDatePicker"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Type')"
                          :items="table.filters.types"
                          item-text="name"
                          item-value="id"
                          v-model="filter.types"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Partner')"
                          :items="getAllPaymentpartners"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          v-model="filter.partners"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Cashiers')"
                          :items="getCashiers"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          v-model="filter.cashiers"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-list-item>
                        <v-text-field
                          :label="$t('Date From')"
                          id="transaction_date_from"
                          autocomplete="off"
                          v-model.lazy="filter.transaction_date_from"
                          solo
                          @keydown.prevent
                        ></v-text-field>
                      </v-list-item>

                      <v-list-item>
                        <v-text-field
                          :label="$t('Date To')"
                          id="transaction_date_to"
                          autocomplete="off"
                          v-model.lazy="filter.transaction_date_to"
                          solo
                          @keydown.prevent
                        ></v-text-field>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>

                <span
                  v-if="table.modal == 'Warehouse Item'"
                  :title="$t('Filter')"
                >
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Category')"
                          :items="categories"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          v-model="filter.categories"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-list-item class="select">
                        <v-text-field
                          :label="$t('Quantity From')"
                          v-model="filter.quantity_from"
                          solo
                        ></v-text-field>
                      </v-list-item>

                      <v-list-item class="select">
                        <v-text-field
                          :label="$t('Quantity To')"
                          v-model="filter.quantity_to"
                          solo
                        ></v-text-field>
                      </v-list-item>

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
                <span v-if="table.modal == 'Student'" :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <!-- <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Grade')"
                          :items="grades"
                          @change="getClassesUnderGradeStudents(filter.grades)"
                          item-text="name"
                          item-value="id"
                          v-model="filter.grades"
                          multiple
                          solo
                        ></v-autocomplete> 
                      </v-list-item>-->
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Grade / Class')"
                          :items="classes"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          v-model="filter.classes"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Year')"
                          :items="table.filters.academicPeriods"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          v-model="filter.academic_periods"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          :label="$t('Gender')"
                          v-model="filter.gender"
                          :items="[
                            { text: $t('Male'), val: 'male' },
                            { text: $t('Female'), val: 'female' },
                          ]"
                          multiple
                          item-text="text"
                          item-value="val"
                          solo
                        ></v-select>
                      </v-list-item>

                      <v-list-item>
                        <v-select
                          :label="$t('Assign To Class')"
                          v-model="filter.assign"
                          :items="[
                            { text: $t('All'), val: 'All' },
                            { text: $t('Assign'), val: 'Assign' },
                            { text: $t('Not Assign'), val: 'Not Assign' },
                          ]"
                          item-text="text"
                          item-value="val"
                          solo
                        ></v-select>
                      </v-list-item>

                      <v-list-item>
                        <v-select
                          :label="$t('Status')"
                          v-model="filter.status_student"
                          :items="[
                            { text: $t('Pending'), val: 'pending' },
                            { text: $t('New'), val: 'new' },
                            { text: $t('Approved'), val: 'approved' },
                            { text: $t('Unapproved'), val: 'unapproved' },
                            { text: $t('Archived'), val: 'archived' },
                          ]"
                          multiple
                          item-text="text"
                          item-value="val"
                          solo
                        ></v-select>
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          :label="$t('Missing Documents')"
                          v-model="filter.files"
                          :items="[
                            { text: $t('Missing'), val: 0 },
                            { text: $t('Not Missing'), val: 1 },
                          ]"
                          item-text="text"
                          item-value="val"
                          solo
                        ></v-select>
                      </v-list-item>

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>

                <!-- warehous transictions filter -->
                <span
                  v-if="table.modal == 'Warehouse Transactin'"
                  :title="$t('Filter')"
                >
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                        @click="IntializeDatePickerWareHouse"
                      ></i>
                    </template>

                    <v-list>
                      <!-- Warehouse -->
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Warehouse')"
                          :items="allwarehouses"
                          @change="
                            hideLabel = true && getCreatedBy(filter.warehouses)
                          "
                          item-text="name"
                          item-value="id"
                          v-model="filter.warehouses"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <!-- Created By -->
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('created By')"
                          :items="createdBy"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.created_by_ids"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <!-- Type -->
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Type')"
                          :items="[
                            { id: 'in', text: $t('in') },
                            { id: 'out', text: $t('out') },
                          ]"
                          @change="hideLabel = true"
                          item-text="text"
                          item-value="id"
                          multiple
                          v-model="filter.types"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <!-- Created by -->
                      <!-- <v-list-item>
                        <v-autocomplete
                          label="Created by"
                          :items="roles"
                          @change="hideLabel = true"
                          item-text="display_name"
                          item-value="id"
                          multiple
                          v-model="filter.createdby"
                          solo
                        ></v-autocomplete>
                      </v-list-item>-->

                      <!-- Partner -->
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Partner')"
                          :items="getAllpartners"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="filter.partners"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <!-- Date from to -->
                      <v-list-item>
                        <v-text-field
                          :label="$t('Date From')"
                          id="warehoustransaction_date_from"
                          autocomplete="off"
                          v-model.lazy="filter.transaction_date_from"
                          solo
                          @keydown.prevent
                        ></v-text-field>
                      </v-list-item>

                      <v-list-item>
                        <v-text-field
                          :label="$t('Date To')"
                          id="warehoustransaction_date_to"
                          autocomplete="off"
                          v-model.lazy="filter.transaction_date_to"
                          solo
                          @keydown.prevent
                        ></v-text-field>
                      </v-list-item>
                      <!-- Status -->
                      <v-list-item>
                        <v-autocomplete
                          :label="$t('Status')"
                          :items="[
                            { text: $t('Confirmed'), value: 1 },
                            { text: $t('Draft'), value: 0 },
                          ]"
                          @change="hideLabel = true"
                          multiple
                          v-model="filter.status"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions>
                        <!-- <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="filterMenu = false"
                          >close</v-btn
                        >-->
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
                <!-- /warehous transictions -->

                <!-- ====================================import And Export================================== -->

                <a
                  big
                  class="main-color"
                  @click="OpenDialogImport"
                  v-if="
                    (table.modal == 'Grade' ||
                      table.modal == 'Item' ||
                      table.modal == 'Student' ||
                      table.modal == 'Teacher') &&
                    importBtnVisible
                  "
                  :title="$t('Import File')"
                >
                  <img src="../../src/assets/icons/upload.svg" />
                </a>
                <a
                  v-can="table.permissions.export"
                  @click.prevent="exportData"
                  href="#"
                  v-if="
                    (table.modal == 'Item' ||
                      table.modal == 'Student' ||
                      table.modal == 'Invoice' ||
                      table.modal == 'Payment' ||
                      table.modal == 'GeneralPayment') &&
                    exportBtnVisible
                  "
                  :title="$t('Export All')"
                >
                  <img src="../../src/assets/icons/download.svg" />
                </a>
                <!-- <v-spacer></v-spacer> -->
                <!-- ===================================== END =================================== -->
                <!-- bulk approve -->
                <v-icon
                  v-if="table.modal == 'Student' && approveBtnVisible"
                  class="main-color"
                  @click="openBulkApproveDialog"
                  :title="$t('Bulk Approve for student')"
                  >check_circle_outline</v-icon
                >
                <v-icon
                  v-if="table.modal == 'Student'"
                  class="main-color"
                  @click="openBroadCastDialog"
                  :title="$t('Contact student relatives')"
                  >add_alert</v-icon
                >
                <!-- :title="" -->

                <!-- bulk delete icon for assign student to class -->
                <v-icon
                  class="main-color"
                  large
                  :title="$t('Delete')"
                  @click="openBulkDeleteDialog"
                  v-if="
                    table.modal == 'assign student to class' && deleteBtnVisible
                  "
                  >delete</v-icon
                >

                <v-icon
                  class="main-color"
                  large
                  @click="openPrintDialog"
                  v-if="table.modal == 'assign student to class'"
                  :title="$t('Print')"
                  >print</v-icon
                >

                <!-- print icon for exam tab  -->
                <v-icon
                  v-if="table.modal == 'Exam'"
                  @click="openPrintExamsDialog"
                  class="main-color"
                  :title="$t('Print')"
                  large
                  >print</v-icon
                >

                <!-- print icon for invoices tab  -->
                <v-icon
                  v-if="table.modal == 'Invoice' && printBtnVisible"
                  @click="PrintSelectedInvoices"
                  class="main-color"
                  :title="$t('Print')"
                  large
                  >print</v-icon
                >
                <!-- print -->
                <v-icon
                  v-if="
                    (table.modal == 'Payment' ||
                      table.modal == 'GeneralPayment') &&
                    printBtnVisible
                  "
                  @click="PrintSelectedItems"
                  :title="$t('Print')"
                  class="main-color"
                  large
                  >print</v-icon
                >

                <!-- bulk upgrade students to academic year -->
                <span
                  class="bulkUpgradeIcon"
                  :title="$t('Bulk Upgrade Students')"
                  v-if="
                    table.modal == 'assign student to class' &&
                    upgradeBtnVisible
                  "
                  @click="bulkPromoteStudentsDialog()"
                >
                  <i class="fas fa-level-up-alt"></i>
                </span>

                <!-- add icon -->
                <v-icon
                  v-can="table.permissions.add"
                  :title="$t('Add New')"
                  v-if="
                    table.modal != 'ApplicantLogs' &&
                    table.modal != 'Warehouse Transactin' &&
                    table.modal != 'Payment' &&
                    table.modal != 'GeneralPayment'
                  "
                  @click="OpenDialogAddEdit"
                  class="main-color"
                  large
                  >add_circle</v-icon
                >
                <!-- add new warehuse transactions -->
                <v-icon
                  v-can="table.permissions.add"
                  :title="$t('Add New')"
                  v-if="table.modal == 'Warehouse Transactin'"
                  @click="OpenNewWarehousTransaction"
                  class="main-color"
                  large
                  >add_circle</v-icon
                >

                <!-- payment -->
                <v-icon
                  v-can="table.permissions.add"
                  :title="$t('Add New')"
                  v-if="table.modal == 'GeneralPayment'"
                  @click="openPaymentCreate"
                  class="main-color"
                  large
                  >add_circle</v-icon
                >

                <!--=============== Students Books ==============-->
                <v-row v-if="table.modal == 'Books List'">
                  <!-- icon -->
                  <v-col cols="12" md="6" sm="6">
                    <v-icon
                      class="main-color"
                      style="padding-top: 7px"
                      large
                      @click="openBookTransactionDialog(null)"
                      :title="$t('books transactions')"
                      >description</v-icon
                    >
                  </v-col>
                  <!-- drop dowen crads -->
                  <v-col cols="12" md="6" sm="6">
                    <v-autocomplete
                      :label="$t('Grades')"
                      :items="studentsbookListGrades"
                      v-if="table.modal == 'Books List'"
                      @change="hideLabel = true && getStudentBookList()"
                      item-text="name"
                      item-value="id"
                      v-model="filter.booksListGrade"
                      solo
                      style="height: 0"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- /Students Books -->

                <v-icon
                  v-can="table.permissions.bulkReset"
                  :title="$t('Bulk Reset')"
                  v-if="table.modal == 'User'"
                  @click="openResetDialog"
                  class="main-color"
                  large
                  >lock</v-icon
                >
                <!-- ================== Activity logs ================== -->
                <v-btn
                  icon
                  :to="getActivityLogLink()"
                  v-if="
                    activitylogPermission &&
                    (table.modal == 'User' ||
                      table.modal == 'Student' ||
                      table.modal == 'Subject Type' ||
                      table.modal == 'Grade' ||
                      table.modal == 'Subject' ||
                      table.modal == 'Qualifier' ||
                      table.modal == 'Teacher' ||
                      table.modal == 'Tax' ||
                      table.modal == 'Cash Point' ||
                      table.modal == 'Payment' ||
                      table.modal == 'Partner' ||
                      table.modal == 'Library' ||
                      table.modal == 'Invoice')
                  "
                  target="_blank"
                >
                  <v-icon class="main-color" large :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>

            <div
              class="headerTable-div"
              v-if="
                canControlCategories &&
                table.modal == 'Library' &&
                table.permissions.list_category
              "
            >
              <!-- BTN Add Category -->
              <div
                :style="
                  currentAppLocale == 'ar'
                    ? 'float:right'
                    : 'text-align: center'
                "
                class="iconadd item-category"
                v-if="table.modal == 'Library' && table.permissions.addCategory"
              >
                <v-icon
                  :title="$t('Add New Category')"
                  v-if="
                    table.modal == 'Library' && table.permissions.addCategory
                  "
                  @click="openCategoryModal"
                  class="main-color"
                  large
                  style="font-size: 65px"
                  >add_circle_outline</v-icon
                >
                <p class="main-color" style="font-size: 12px">
                  {{ $t("Add Category") }}
                </p>
              </div>
              <!-- category -->
              <div
                style="text-align: center"
                class="item-category"
                v-for="(category, index) in categories"
                :key="index"
              >
                <div class="circle">
                  <img class="category" :src="category.icon" />
                </div>
                <p style="font-size: 12px; margin-bottom: 0">
                  {{ category.name }}
                </p>
                <v-icon
                  :title="$t('Delete Category')"
                  v-if="
                    table.modal == 'Library' && table.permissions.deleteCategory
                  "
                  @click="deleteCategory(category.id, index)"
                  small
                  >delete</v-icon
                >
                <v-icon
                  :title="$t('Edit Category')"
                  v-if="
                    table.modal == 'Library' && table.permissions.editCategory
                  "
                  @click="editCategory(category)"
                  small
                  >edit</v-icon
                >
              </div>
            </div>
          </template>
          <!-- ==================================== partners =====================================================-->

          <template v-slot:item.type="{ item }" v-if="table.modal == 'Partner'">
            {{ $t(item.type) }}
          </template>
          <template
            v-slot:item.group="{ item }"
            v-if="table.modal == 'Partner'"
          >
            {{ $t(item.group) }}
          </template>
          <!-- ==================================== end partners =====================================================-->
          <template
            v-slot:item.color_hex="{ item }"
            v-if="table.modal == 'Grade'"
          >
            <span
              :style="{ backgroundColor: item.color_hex }"
              class="colorItem"
            ></span>
          </template>
          <template
            v-slot:item.item_transaction_count="{ item }"
            v-if="table.modal == 'Warehouse Item'"
          >
            <a
              v-if="item.item_transaction_count"
              dark
              :href="'/WarehouseTransactionItem/' + item.item_id"
              >{{ item.item_transaction_count }}</a
            >
          </template>
          <template
            v-slot:item.transaction_id="{ item }"
            v-if="table.modal == 'Warehouse Transactin'"
          >
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <a
                  v-if="viewtransaction && item.transaction_id"
                  dark
                  v-on="on"
                  :href="'/warehouses_transactions/show/' + item.id"
                  >{{ item.transaction_id.slice(0, 20) }}</a
                >
                <p dark v-on="on" v-else>
                  {{ item.transaction_id.slice(0, 20) }}
                </p>
              </template>
              <span>{{ item.transaction_id }}</span>
            </v-tooltip>
          </template>
          <template
            v-slot:item.transaction_date="{ item }"
            v-if="table.modal == 'Warehouse Transactin'"
          >
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.transaction_date">
                  {{ item.transaction_date.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.transaction_date }}</span>
            </v-tooltip>
          </template>

          <template
            v-slot:item.transaction_date="{ item }"
            v-if="table.modal == 'Warehouse Transactin'"
          >
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.transaction_date">
                  {{ item.transaction_date.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.transaction_date }}</span>
            </v-tooltip>
          </template>

          <template
            v-slot:item.partner="{ item }"
            v-if="table.modal == 'Warehouse Transactin'"
          >
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.partner">
                  {{ item.partner.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.partner }}</span>
            </v-tooltip>
          </template>

          <template
            v-slot:item.total_price="{ item }"
            v-if="table.modal == 'Warehouse Transactin'"
          >
            <span
              class="myspan"
              style="text-align: right !important; display: block"
              >{{ item.total_price }}</span
            >
          </template>

          <template
            v-slot:item.tax="{ item }"
            v-if="table.modal == 'Warehouse Transactin'"
          >
            <span
              class="myspan"
              style="text-align: right !important; display: block"
              >{{ item.tax }}</span
            >
          </template>

          <template
            v-slot:item.total_after_tax="{ item }"
            v-if="table.modal == 'Warehouse Transactin'"
          >
            <span
              class="myspan"
              style="text-align: right !important; display: block"
              >{{ item.total_after_tax }}</span
            >
          </template>

          <template
            v-slot:item.confirmed="{ item }"
            v-if="table.modal == 'Warehouse Transactin'"
          >
            <span>{{
              item.confirmed == 1 ? $t("Confirmed") : $t("Draft")
            }}</span>
          </template>

          <!-- ====================================  Discussion Room Table =====================================================-->
          <!-- <template v-slot:item.name="{ item }" v-if="table.modal == 'Discussion Room'">
            <router-link
              target="_blank"
              :to="'/discussionboard/' + item.id"
              v-if="item.id"
            >{{ item.name }} sssss</router-link>
          </template>-->

          <!-- Discussion Room -->
          <template
            v-slot:item.about="{ item }"
            v-if="table.modal == 'Discussion Room'"
          >
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.about">
                  {{ item.about.slice(0, 10) }}
                </p>
              </template>
            </v-tooltip>
          </template>
          <!-- <template v-slot:item.name="{ item }" v-if="table.modal == 'Discussion Room'">
            <span>{{ item.name }} ahmeeejkadsljasdjl</span>
          </template>-->

          <template
            v-slot:item.name="{ item }"
            v-if="
              table.modal == 'Discussion Room' ||
              table.modal == 'Exam' ||
              table.modal == 'Teacher'
            "
          >
            <v-tooltip
              bottom
              max-width="2800px"
              v-if="table.modal == 'Teacher'"
            >
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.name">
                  {{ item.name.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
            <p v-if="table.modal == 'Exam'">{{ item.name }}</p>
            <!-- <v-tooltip bottom max-width="2800px" v-if="table.modal == 'Discussion Room' ">

          </template>
          <template v-slot:item.name="{ item }" v-if="table.modal == 'Discussion Room'">
            <v-tooltip bottom max-width="2800px">

              <template v-slot:activator="{ on }">
                <a
                  v-if="table.modal == 'Discussion Room' && item.id"
                  target="_blank"
                  :to="'/discussionboard/' + item.id"
                  dark
                  v-on="on"
                >{{ item.name.slice(0, 10) }}</a>

              </template>
            </v-tooltip>-->

            <router-link
              v-if="table.modal == 'Discussion Room' && item.id"
              target="_blank"
              :to="'/discussionboard/' + item.id"
              >{{ item.name }}</router-link
            >
          </template>
          <!-- ==========exams tab template============== -->
          <!-- <template v-slot:item.name="{ item }" v-if="table.modal == 'Exam'">
            <p v-if="listExamMarksVisible">{{ item.name }}</p>
          </template>-->
          <!-- Teacher name -->
          <!-- <template v-if="table.modal == 'Teacher'" v-slot:item.name="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.name">{{ item.name.slice(0, 10) }}</p>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </template>-->
          <!-- /Teacher name -->

          <template
            v-slot:item.about="{ item }"
            v-if="table.modal == 'Discussion Room'"
          >
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.about">
                  {{ item.about.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.about }}</span>
            </v-tooltip>
          </template>
          <template
            v-slot:item.classes="{ item }"
            v-if="table.modal == 'Discussion Room'"
          >
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.classes">
                  {{ item.classes.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.classes }}</span>
            </v-tooltip>
          </template>
          <!-- ====================================  end Discussion Room Table =====================================================-->
          <template v-slot:item.notes="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.notes">
                  {{ item.notes.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.notes }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.en.notes="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.en.notes">
                  {{ item.en.notes.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.en.notes }}</span>
            </v-tooltip>
          </template>

          <!-- ====================================  Invoice =====================================================-->

          <!-- ====================================  Invoice =====================================================-->

          <template
            v-slot:item="{ item, isSelected, select }"
            v-if="table.modal == 'Invoice'"
          >
            <tr :data-id="item.id">
              <td :class="customRowClass(item)">
                <v-simple-checkbox
                  :value="isSelected"
                  :readonly="item.status == 'deleted'"
                  :disabled="item.status == 'deleted'"
                  @input="select($event)"
                  v-if="item.status != 'deleted'"
                ></v-simple-checkbox>
              </td>
              <td :class="customRowClass(item)">
                <router-link
                  :to="'/invoices/' + item.id"
                  v-if="editBtnVisible"
                  >{{ item.invoice_number }}</router-link
                >
                <p v-if="!editBtnVisible">{{ item.invoice_number }}</p>
              </td>
              <td :class="customRowClass(item)">{{ item.partner_code }}</td>
              <td :class="customRowClass(item)">
                <StudentHoverMenu
                  :studentName="item.partner_name"
                  :studentId="item.student_id"
                  :userId="item.user_id"
                  :archive="item.archive"
                  v-if="item.student_id"
                ></StudentHoverMenu>
                <span v-else>{{ item.partner_name }}</span>
              </td>

              <td :class="customRowClass(item)">{{ item.student_grade }}</td>
              <td :class="customRowClass(item)">{{ item.invoice_date }}</td>
              <td :class="customRowClass(item)">{{ item.status }}</td>
              <td :class="customRowClass(item)">{{ item.total }}</td>
              <td :class="customRowClass(item)">
                {{ item.total_after_discount }}
              </td>
              <td :class="customRowClass(item)">
                <p v-if="item.payment_status == 'Paid'" class="text-green">
                  {{ $t(item.payment_status) }}
                </p>

                <v-tooltip top v-if="item.payment_status == 'Partially Paid'">
                  <template v-slot:activator="{ on, attrs }">
                    <p class="text-yellow" v-on="on" v-bind="attrs" dark>
                      {{ $t(item.payment_status) }}
                    </p>
                  </template>
                  <span
                    >{{ $t("Remaining Amount") }} {{ item.amount_remaining }}
                    {{ $t("SAR") }}</span
                  >
                </v-tooltip>

                <p v-if="item.payment_status == 'Not Paid'" class="text-red">
                  {{ $t(item.payment_status) }}
                </p>
              </td>
              <td>
                <v-icon
                  v-if="
                    editBtnVisible &&
                    table.modal == 'Invoice' &&
                    item.status != 'deleted' &&
                    item.automatic == 0
                  "
                  :title="$t('Edit')"
                  @click="openInvoice(item)"
                  >{{ "edit" }}</v-icon
                >
                <v-icon
                  :disabled="
                    table.modal == 'Invoice' && item.status == 'deleted'
                  "
                  :title="Delete"
                  v-if="
                    item.status != 'deleted' &&
                    item.automatic == 0 &&
                    !table.parent
                  "
                  @click="showDialog(item)"
                  >{{ "delete" }}</v-icon
                >
                <v-icon
                  v-if="
                    table.modal == 'Invoice' &&
                    printBtnVisible &&
                    item.status != 'deleted'
                  "
                  @click="printInvoice(item.id)"
                  :disabled="
                    table.modal == 'Invoice' && item.status == 'deleted'
                  "
                  :title="$t('Invoice')"
                  >print</v-icon
                >
                <v-icon
                  v-if="
                    table.modal == 'Invoice' &&
                    printBtnVisible &&
                    item.status != 'deleted'
                  "
                  @click="PrintInvoiceTransactions(item)"
                  :disabled="
                    table.modal == 'Invoice' && item.status == 'deleted'
                  "
                  :title="$t('Invoice Details')"
                  outlined
                  >assignment</v-icon
                >
              </td>
            </tr>
          </template>
          <!-- ====================================  End Invoice =====================================================-->

          <!-- ====================================  Payment =====================================================-->
          <template
            v-slot:body="{ items }"
            v-if="table.modal == 'Payment' || table.modal == 'GeneralPayment'"
          >
            <tbody>
              <tr
                v-for="item in items"
                :key="item"
                :class="item.refund ? 'redRow' : ''"
              >
                <td class="d-block d-sm-table-cell">
                  <v-checkbox
                    v-model="selected"
                    :value="item"
                    style="margin: 0px; padding: 0px"
                    hide-details
                  />
                </td>
                <td class="d-block d-sm-table-cell">
                  <router-link
                    :to="'/payments/' + item.id"
                    v-if="viewBtnVisible && table.modal == 'Payment'"
                  >
                    {{ item.transaction_id }}
                    <!-- <v-tooltip
                      bottom
                      max-width="200px"
                      v-if="item.transaction_id"
                    >
                      <template v-slot:activator="{ on }">
                        <p dark v-on="on">
                          {{ item.transaction_id.slice(0, 10) }}
                        </p>
                      </template>
                      <span>{{ item.transaction_id }}</span>
                    </v-tooltip> -->
                  </router-link>
                  <router-link
                    :to="
                      item.refund == 0
                        ? '/generalPayment/' + item.id
                        : '/viewReverseGeneralPayment/' + item.id
                    "
                    v-else-if="
                      viewBtnVisible && table.modal == 'GeneralPayment'
                    "
                  >
                    {{ item.transaction_id }}
                    <!-- <v-tooltip
                      bottom
                      max-width="200px"
                      v-if="item.transaction_id"
                    >
                      <template v-slot:activator="{ on }">
                        <p dark v-on="on">
                          {{ item.transaction_id.slice(0, 10) }}
                        </p>
                      </template>
                      <span>{{ item.transaction_id }}</span>
                    </v-tooltip> -->
                  </router-link>
                  <p v-else>
                    {{ item.transaction_id }}
                    <!-- <v-tooltip
                      bottom
                      max-width="200px"
                      v-if="item.transaction_id"
                    >
                      <template v-slot:activator="{ on }">
                        <p dark v-on="on">
                          {{ item.transaction_id.slice(0, 10) }}
                        </p>
                      </template>
                      <span>{{ item.transaction_id }}</span>
                    </v-tooltip> -->
                  </p>
                </td>
                <td class="d-block d-sm-table-cell">
                  <span>{{ item.transaction_date }}</span>
                  <!-- <v-icon
                    medium
                    style="float: left; opacity: 0.8; margin-left: 5px"
                    class="mr-2 dateIcon"
                    :class="item.refund ? 'redRow' : 'main-color'"
                    >event</v-icon
                  > -->
                </td>
                <td class="d-block d-sm-table-cell">{{ item.type }}</td>
                <td class="d-block d-sm-table-cell">
                  {{ item.reference_no }}
                  <!-- <v-tooltip bottom max-width="500px" v-if="item.reference_no">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">{{ item.reference_no.slice(0, 10) }}</p>
                    </template>
                    <span>{{ item.reference_no }}</span>
                  </v-tooltip> -->
                </td>
                <td class="d-block d-sm-table-cell">
                  {{ item.amount }} {{ $t("SAR") }}
                </td>
                <td class="d-block d-sm-table-cell">
                  <v-tooltip bottom max-width="200px" v-if="item.partner">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">{{ item.partner.slice(0, 10) }}</p>
                    </template>
                    <span>{{ item.partner }}</span>
                  </v-tooltip>
                </td>
                <td
                  class="d-block d-sm-table-cell"
                  v-if="table.modal == 'Payment'"
                >
                  {{ item.invoic_number }}
                  <!-- <v-tooltip bottom max-width="200px" v-if="item.invoic_number">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">
                        {{ item.invoic_number.slice(0, 10) }}
                      </p>
                    </template>
                    <span>{{ item.invoic_number }}</span>
                  </v-tooltip> -->
                  <!-- {{ item.invoic_number }} -->
                </td>
                <td class="d-block d-sm-table-cell">
                  <v-tooltip bottom max-width="200px" v-if="item.cashier">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">{{ item.cashier.slice(0, 10) }}</p>
                    </template>
                    <span>{{ item.cashier }}</span>
                  </v-tooltip>
                </td>

                <td class="d-block d-sm-table-cell">
                  <!-- payment templete === -->

                  <v-btn
                    icon
                    v-if="refundBtnVisible && table.modal == 'Payment'"
                  >
                    <v-icon
                      @click="refund(item)"
                      v-if="
                        refundBtnVisible &&
                        !item.refund &&
                        table.modal == 'Payment'
                      "
                      :title="$t('Refund Payment')"
                      >{{ "cached" }}</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    v-if="refundBtnVisible && table.modal == 'GeneralPayment'"
                  >
                    <v-icon
                      @click="refund(item)"
                      v-if="
                        refundBtnVisible &&
                        !item.refund &&
                        table.modal == 'GeneralPayment' &&
                        item.is_confirmed == 1 &&
                        item.has_children == 0
                      "
                      :title="$t('Refund Payment')"
                      >{{ "cached" }}</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    v-if="printBtnVisible && table.modal == 'Payment'"
                  >
                    <v-icon
                      v-if="printBtnVisible"
                      @click="printItem(item.id)"
                      :title="$t('Print')"
                      >print</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    v-if="
                      printBtnVisible &&
                      table.modal == 'GeneralPayment' &&
                      item.is_confirmed == 1
                    "
                  >
                    <v-icon @click="printItem(item.id)" :title="$t('Print')"
                      >print</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    v-if="
                      editBtnVisible &&
                      table.modal == 'GeneralPayment' &&
                      item.is_confirmed == 0 &&
                      item.refund == 0
                    "
                  >
                    <v-icon @click="editItem(item.id)" :title="$t('Edit')"
                      >edit</v-icon
                    >
                  </v-btn>
                  <v-btn icon v-if="editBtnVisible && table.modal == 'Payment'">
                    <v-icon @click="editItem(item.id)" :title="$t('Edit')"
                      >edit</v-icon
                    >
                  </v-btn>
                  <v-btn icon v-if="viewBtnVisible">
                    <v-icon
                      @click="viewPayment(item)"
                      :title="$t('Show Payment')"
                      >remove_red_eye</v-icon
                    >
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
          <!-- ====================================  End Payment =====================================================-->

          <!--===============subject types==============-->
          <template v-slot:item.en.notes="{ item }">
            <v-tooltip bottom max-width="200px" v-if="item.en.notes">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">{{ item.en.notes.slice(0, 10) }}</p>
              </template>
              <span>{{ item.en.notes }}</span>
            </v-tooltip>
          </template>
          <template
            v-if="table.modal == 'All Exam Results'"
            v-slot:item.exam_name="{ item }"
          >
            <router-link
              :to="'/examTimer/' + item.exam_id"
              v-if="item.exam_date_link_active == 1"
              >{{ item.exam_name }}</router-link
            >
            <p v-if="!item.exam_date_link_active">{{ item.exam_name }}</p>
          </template>

          <!-- BankReceipts.vue applicant_name -->

          <template
            v-slot:item.applicant_name="{ item }"
            v-if="table.modal == 'BankReceipt'"
          >
            <StudentHoverMenu
              :studentName="item.applicant_name"
              :studentId="item.student_id"
              :userId="item.user_id"
              v-if="item.student_id"
            ></StudentHoverMenu>
            <span v-else>
              {{ item.applicant_name }}
            </span>
          </template>
          <template
            v-slot:item.actions="{ item }"
            v-if="table.modal == 'BankReceipt'"
          >
            <v-icon
              @click="$router.push('/bankReceiptsView/' + item.id)"
              style="curosel: pointer"
              v-if="table.modal == 'BankReceipt'"
              >remove_red_eye</v-icon
            >

            <!-- <v-icon
              @click="openBookTransactionDialog(item)"
              style="curosel: pointer"
              :title="$t('Books Transaction')"
              v-if="table.modal == 'Books List'"
              >description</v-icon
            >
            <v-icon
              @click="printBookList(item.studentID)"
              v-if="table.modal == 'Books List'"
              style="curosel: pointer"
              :title="$t('Print')"
              >print</v-icon
            > -->
          </template>

          <!-- ==========Fee plan template============== -->
          <template
            v-slot:item.en.title="{ item }"
            v-if="table.modal == 'Fee Plan'"
          >
            <router-link :to="'/MasterFees/' + item.id" v-if="viewBtnVisible">{{
              item.en.title
            }}</router-link>
            <p v-if="!viewBtnVisible">{{ item.en.title }}</p>
          </template>
          <!-- ====================================  end Fee plan template ====================================-->
          <!-- ====================================  My Library =====================================================-->
          <template
            v-slot:item.filename="{ item }"
            v-if="table.modal == 'Library' || table.modal == 'My Library'"
          >
            <v-icon
              class="main-color"
              v-if="item.Type == 'File' && item.file_type == 'Video'"
              >movie</v-icon
            >
            <v-icon
              class="main-color"
              v-if="item.Type == 'File' && item.file_type == 'Audio'"
              >audiotrack</v-icon
            >
            <v-icon
              class="main-color"
              v-if="item.Type == 'File' && item.file_type == 'Document'"
              >description</v-icon
            >
            <v-icon
              class="main-color"
              v-if="item.Type == 'File' && item.file_type == 'Image'"
              >image</v-icon
            >
            <v-icon class="main-color" v-if="item.Type == 'Url'"
              >language</v-icon
            >
            <v-tooltip
              bottom
              max-width="700px"
              v-if="item.filename"
              color="#eaeefc"
            >
              <template v-slot:activator="{ on }">
                <a
                  :href="
                    item.download_url && item.download_url.startsWith('http')
                      ? item.download_url
                      : 'https://' + item.download_url
                  "
                  target="_blank"
                  v-on="on"
                  >{{ item.filename.slice(0, 10) }}</a
                >
              </template>
              <a
                :href="
                  item.download_url && item.download_url.startsWith('http')
                    ? item.download_url
                    : 'https://' + item.download_url
                "
                target="_blank"
                >{{ item.filename }}</a
              >
            </v-tooltip>
          </template>
          <!-- weeks -->
          <template v-slot:item.academic_weeks="{ item }">
            <span
              v-if="item.academic_weeks.length > 1"
              class="main-color"
              style="cursor: pointer"
              @click="openModalAcadimceWeeks(item.academic_weeks)"
            >
              {{ item.academic_weeks[0].slice(0, 7) }} ....
            </span>

            <span v-else-if="item.academic_weeks.length == 1">
              {{ item.academic_weeks[0] }}
            </span>
            <span v-else> </span>
          </template>
          <template v-slot:item.notes="{ item }">
            <v-tooltip bottom max-width="200px" v-if="item.notes">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">{{ item.notes.slice(0, 10) }}</p>
              </template>
              <span>{{ item.notes }}</span>
            </v-tooltip>
          </template>

          <!-- ====================================  End My Library =====================================================-->

          <!-- invoice tab tamplete -->
          <template
            v-slot:item.invoice_number="{ item }"
            v-if="table.modal == 'Invoice'"
          >
            <router-link :to="'/invoices/' + item.id" v-if="editBtnVisible">{{
              item.invoice_number
            }}</router-link>
            <p v-if="!editBtnVisible">{{ item.invoice_number }}</p>
          </template>

          <!-- ==========Teacher tab template============== -->
          <template
            v-slot:item.subject_names="{ item }"
            v-if="table.modal == 'Teacher'"
          >
            <p :title="item.subject_names.join(' / ')">
              {{ item.subject_names_view.join(" / ") }}
            </p>
          </template>
          <template
            v-slot:item.grade_names="{ item }"
            v-if="table.modal == 'Teacher'"
          >
            <p :title="item.grade_names.join(' / ')">
              {{ item.grade_names_view.join(" / ") }}
            </p>
          </template>
          <!-- payment_method_text -->
          <template
            v-slot:item.payment_method_text="{ item }"
            v-if="table.modal == 'Cash Point'"
          >
            <span v-for="methode in item.payment_method_text">
              {{ $t(methode) }},
            </span>
          </template>

          <!-- ========================All Taxes view ==================== -->

          <template v-slot:item.active="{ item }">
            <v-icon v-if="item.active" class="success--text" dark>check</v-icon>
          </template>

          <!-- ======================== Cash Points view ==================== -->
          <!-- <template
            v-slot:item.active="{ item }"
            v-if="table.modal == 'Cash Point'"
          >
            <v-icon class="success--text" v-if="item.active">done</v-icon>
            <span v-if="!item.active"></span>
          </template>-->

          <template
            v-slot:item.payment_method="{ item }"
            v-if="table.modal == 'Cash Point'"
          >
            {{ arrayTostringAndUperCase(item.payment_method) }}
          </template>

          <template
            v-slot:item.OnlinePayment="{ item }"
            v-if="table.modal == 'Cash Point'"
          >
            <v-switch v-model="item.OnlinePayment" disabled></v-switch>
          </template>

          <template
            v-slot:item.status_student="{ item }"
            v-if="table.modal == 'Student'"
          >
            <div
              v-if="item.status_student == 'pending'"
              style="color: #ffb400"
              class="status"
            >
              {{ $t(item.status_student) }}
            </div>
            <div
              v-if="item.status_student == 'new'"
              style="color: #ffb400"
              class="status"
            >
              {{ $t(item.status_student) }}
            </div>
            <div
              v-if="item.status_student == 'approved'"
              style="color: #4caf50"
              class="status"
            >
              {{ $t(item.status_student) }}
            </div>
            <div
              v-if="item.status_student == 'unapproved'"
              style="color: #ff5252"
              class="status"
            >
              {{ $t(item.status_student) }}
            </div>
          </template>

          <!-- start parteners view -->
          <template
            v-slot:item.partner_name="{ item }"
            v-if="table.modal == 'Partner'"
          >
            <StudentHoverMenu
              :studentName="item.partner_name"
              :studentId="item.student_id"
              :userId="item.user_id"
              :archive="item.archive"
              v-if="item.student_id"
            ></StudentHoverMenu>

            <span v-else>{{ item.partner_name }}</span>
          </template>
          <!-- end parteners view -->

          <template
            v-slot:item.changes="{ item }"
            v-if="table.modal == 'ApplicantLogs'"
          >
            <div class="panel-group" v-if="item.changes.length > 0">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <p class="panel-title">
                    <a
                      data-toggle="collapse"
                      class="show-more"
                      :href="'#collapse' + item.id"
                      >{{ $t("Show Changes") }}</a
                    >
                  </p>
                </div>
                <div :id="'collapse' + item.id" class="panel-collapse collapse">
                  <ul style="padding-left: 0; list-style: disc">
                    <li
                      style="margin-bottom: 10px"
                      v-for="(change, index) in item.changes"
                      :key="index"
                      v-html="change"
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:item.allow_vat="{ item }">
            <v-icon
              v-if="item.allow_vat"
              class="pt-2 text-center center"
              color="green"
              dark
              >check</v-icon
            >

            <!-- <v-icon v-if="!item.allow_vat">close </v-icon> -->
          </template>
          <template v-slot:item.allow_discount="{ item }">
            <v-icon
              v-if="item.allow_discount"
              class="pt-2 text-center center"
              color="green"
              dark
              >check</v-icon
            >

            <!-- <v-icon v-if="!item.allow_vat">close </v-icon> -->
          </template>

          <!-- column Show password in users table -->
          <template
            v-slot:item.plain_password="{ item }"
            v-if="table.modal == 'User'"
          >
            <p v-if="item.plain_password && !item.show_password">******</p>
            <p v-else>{{ item.plain_password }}</p>
          </template>

          <template
            v-slot:item.name="{ item }"
            v-if="table.modal == 'Discussion Room'"
          >
            <router-link
              target="_blank"
              :to="'/discussionboard/' + item.id"
              v-if="item.id"
              >{{ item.name }}</router-link
            >
          </template>

          <template
            v-slot:item.application_number="{ item }"
            v-if="table.modal == 'Student'"
          >
            <router-link
              target="_blank"
              :to="'/students/' + item.student_id"
              v-if="item.student_id && viewBtnVisible"
              >{{ item.application_number }}</router-link
            >
            <p v-if="!item.student_id || !viewBtnVisible">
              {{ item.application_number }}
            </p>
          </template>

          <!-- =============== Student Name =============== -->
          <template
            v-if="table.modal == 'Student'"
            v-slot:item.student_name="{ item }"
          >
            <StudentHoverMenu
              :studentName="item.student_name"
              :studentId="item.student_id"
              :userId="item.user_id"
              v-if="item.student_id"
            ></StudentHoverMenu>
            <span v-else>{{ item.student_name }}</span>
          </template>
          <!-- =================== /student name ================ -->
          <!-- assign student to class - class management - Student Name -->
          <template
            v-if="table.modal == 'assign student to class'"
            v-slot:item.student_full_name="{ item }"
          >
            <StudentHoverMenu
              :studentName="item.student_full_name"
              :studentId="item.id"
              :userId="item.user_id"
              v-if="item.id"
            ></StudentHoverMenu>
            <span v-else>{{ item.student_full_name }}</span>
          </template>
          <!-- StudentBooksList.vue name templae -->
          <template
            v-slot:item.student_name="{ item }"
            v-if="table.modal == 'Books List'"
          >
            <StudentHoverMenu
              :studentName="item.student_name"
              :studentId="item.studentID"
              :userId="item.user_id"
              v-if="item.studentID"
            ></StudentHoverMenu>
            <span v-else>{{ item.student_name }}</span>
          </template>
          <!-- end StudentBooksList.vue name templae -->

          <!--===============Books List actions==============-->
          <!-- <v-icon title="" class="main-color" large>description</v-icon> -->

          <template v-slot:item.actions="{ item }">
            <!-- Warehouse Transactin actions -->
            <v-icon
              @click="openBookTransactionDialog(item)"
              style="curosel: pointer"
              :title="item.student_name"
              v-if="table.modal == 'Books List'"
              >description</v-icon
            >
            <v-icon
              @click="printBookList(item.studentID)"
              style="curosel: pointer"
              :title="$t('Print')"
              v-if="table.modal == 'Books List'"
              >print</v-icon
            >
            <v-icon
              v-if="printBtnVisible && table.modal == 'Warehouse Transactin'"
              @click="printTransaction(item.id)"
              :title="$t('Print')"
              style="cursor: pointer"
              >print</v-icon
            >
            <v-icon
              v-if="
                !item.confirmed &&
                table.permissions.edit &&
                table.modal == 'Warehouse Transactin'
              "
              @click="editWarehouseTransactin(item.id)"
              style="cursor: pointer"
              :title="$t('Edit')"
              >mdi-pencil</v-icon
            >

            <!-- open print exam Dialog -->
            <v-icon
              class="mr-2"
              @click="showCopyExamDialog(item.id)"
              v-if="
                copyExamBtnVisible && item.online_exam && table.modal == 'Exam'
              "
              :title="$t('Copy Exam')"
              >file_copy</v-icon
            >
            <router-link
              :to="
                '/studentsexamresults/' +
                $route.params.academicYear +
                '/' +
                item.class_id +
                '/' +
                item.id
              "
              v-if="listExamMarksVisible && table.modal == 'Exam'"
              :title="$t('View Student Exam Results')"
            >
              <v-icon>assignment</v-icon>
            </router-link>

            <router-link
              :to="'/viewOnlineExam/' + item.id"
              v-if="
                listExamMarksVisible &&
                table.modal == 'Exam' &&
                item.online_exam &&
                item.saved
              "
              :title="$t('View Online Exam')"
            >
              <img src="../assets/elearning-online-gray.svg" alt />
            </router-link>

            <!-- view list student joined  -->
            <router-link
              :to="
                '/viewStudentJoined/' +
                $route.params.academicYear +
                '/' +
                item.class_id +
                '/' +
                item.id
              "
              v-if="
                listExamMarksVisible &&
                table.modal == 'Exam' &&
                item.online_exam
              "
              :title="$t('view list student joined')"
            >
              <v-icon>mdi-dialpad</v-icon>
              <!-- <i class="fa fa-area-chart" aria-hidden="true"></i> -->
            </router-link>

            <v-icon
              @click="edit(item)"
              v-if="
                editBtnVisible &&
                table.modal != 'Student' &&
                table.modal != 'Item' &&
                table.modal != 'assign student to class' &&
                table.modal != 'Invoice' &&
                table.modal != 'Warehouse Transactin' &&
                table.modal != 'Library'
              "
              :title="$t('Edit')"
              >edit</v-icon
            >
            <!-- edit / Delete library action -->
            <v-icon
              v-if="
                editBtnVisible &&
                table.modal == 'Library' &&
                item.can_edit_delete
              "
              @click="edit(item)"
              :title="$t('Edit')"
              >edit</v-icon
            >
            <v-icon
              v-if="
                deleteBtnVisible &&
                table.modal == 'Library' &&
                item.can_edit_delete
              "
              @click="showDialog(item)"
              :title="$t('Delete')"
              >delete</v-icon
            >
            <!-- edit / Delete library action -->

            <v-icon
              v-if="editBtnVisible && table.modal == 'Item'"
              @click="edit(item)"
              :title="$t('Edit')"
              >edit</v-icon
            >
            <v-icon
              v-if="editBtnVisible && table.modal == 'Invoice'"
              @click="openInvoice(item)"
              :disabled="item.status == 'deleted'"
              :title="$t('Edit')"
              >edit</v-icon
            >

            <i
              v-can="'teacher-schedule'"
              v-if="table.modal == 'Teacher'"
              :title="$t('Schedule')"
              @click="goToSchedule(item)"
              style="color: rgba(0, 0, 0, 0.54); cursor: pointer"
              class="fas fa-table"
            ></i>
            <v-icon
              @click="edit(item)"
              v-if="
                editBtnVisible && table.modal == 'Student' && item.student_id
              "
              :title="$t('Edit')"
              >edit</v-icon
            >

            <v-icon
              @click="view(item)"
              v-if="
                table.modal == 'Student' && item.student_id && viewBtnVisible
              "
              :title="$t('View Profile')"
              >remove_red_eye</v-icon
            >
            <v-icon
              @click="openPackageStudent(item)"
              v-if="table.modal == 'Student' && AssignDiscountPackageBtnVisible"
              :title="$t('Add Discounts')"
              >lock</v-icon
            >
            <v-icon
              v-if="
                table.modal == 'Student' && DeassignDiscountPackageBtnVisible
              "
              class="main-color"
              @click="deassignPackage(item)"
              :title="$t('de-assign the package')"
              >mdi-book-minus</v-icon
            >
            <v-icon
              v-if="
                table.modal == 'Student' &&
                item.archive == 0 &&
                archiveStudentBtnVisible
              "
              @click="archiveStudent(item)"
              :title="$t('Archive')"
              >archive</v-icon
            >
            <v-icon
              @click="unarchiveStudent(item)"
              :title="$t('Unarchive')"
              v-if="table.modal == 'Student' && item.archive == 1"
              >unarchive</v-icon
            >

            <v-icon
              v-if="
                deleteBtnVisible &&
                table.modal != 'Item Category' &&
                table.modal != 'Item' &&
                table.modal != 'Warehouse Transactin' &&
                table.modal != 'Library' &&
                table.modal != 'Student'
              "
              :disabled="table.modal == 'Invoice' && item.status == 'deleted'"
              @click="showDialog(item)"
              :title="$t('Delete')"
              >delete</v-icon
            >
            <v-icon
              v-if="
                deleteBtnVisible &&
                (table.modal == 'Item Category' || table.modal == 'Item')
              "
              @click="showDialog(item)"
              :title="$t('Delete')"
              >{{ "delete" }}</v-icon
            >

            <v-icon
              v-if="table.modal == 'User' && changePasswordBtnVisible"
              @click="showPasswordDialog(item)"
              :title="$t('Reset Password')"
              >{{ "lock" }}</v-icon
            >
            <!-- Show Password icon   @click="showPassword(item)"-->

            <v-icon
              class="show-password-icon"
              v-if="
                table.modal == 'User' && !item.show_password && viewPassword
              "
              :title="$t('View Password')"
              @click="item.show_password = true"
              >mdi-eye</v-icon
            >
            <v-icon
              class="show-password-icon"
              v-if="table.modal == 'User' && item.show_password && viewPassword"
              :title="$t('Hide Password')"
              @click="item.show_password = false"
              >mdi-eye-off</v-icon
            >
            <v-btn
              v-if="
                table.modal == 'User' &&
                LoginAsBtnVisible &&
                item.id != user_id &&
                item.id != 1 &&
                (loginAs == false || loginAs == 'false')
              "
              @click="loginAsUser(item)"
              :title="$t('login as')"
              :loading="loading"
              :disabled="loading"
              icon
            >
              <v-icon>mdi-login</v-icon>
            </v-btn>
            <v-icon
              v-if="
                table.modal == 'User' &&
                LoginAsBtnVisible &&
                item.id != user_id &&
                (loginAs == true || loginAs == 'true')
              "
              @click="deImpersonate"
              title="switch back to original user"
              >swap_horiz</v-icon
            >

            <v-icon
              v-if="
                table.modal == 'Fee Plan' &&
                item.confirmation &&
                printBtnVisible
              "
              @click="printPage(item.id)"
              :title="$t('Print')"
              >print</v-icon
            >

            <v-icon
              v-if="table.modal == 'Student' && printBtnVisible"
              @click="printPageStudent(item.student_id)"
              :title="$t('Print')"
              >print</v-icon
            >

            <v-icon
              v-if="table.modal == 'Invoice' && printBtnVisible"
              @click="printInvoice(item.id)"
              :disabled="table.modal == 'Invoice' && item.status == 'deleted'"
              :title="$t('Print')"
              >print</v-icon
            >
            <v-icon
              v-if="table.modal == 'Payment' && printBtnVisible"
              @click="printItem(item.id)"
              :title="$t('print')"
              >print</v-icon
            >
            <span
              class="upgradeIcon"
              v-if="
                table.modal == 'assign student to class' && upgradeBtnVisible
              "
              @click="openPromoteStudentsDialog(item)"
              :title="$t('Upgrade Student')"
            >
              <i class="fas fa-level-up-alt"></i>
            </span>
          </template>
          <template
            v-if="table.modal == 'DiscountPackage'"
            v-slot:item.amount="{ item }"
          >
            <span style="">{{ item.amount }}</span>
            <span>%</span>
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2" v-can="table.permissions.list">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>
        <!-- ========================================dialogReset ======================================  -->
        <v-dialog
          v-model="dialog"
          persistent
          max-width="300"
          class="dialog_confirm"
        >
          <v-card>
            <v-card-title class="headline">{{
              $t("Confirmation Message")
            }}</v-card-title>

            <v-card-text v-if="table.modal != 'assign student to class'">
              {{ $t("Are you sure you want to permanently remove this") }}
              <!--{{ table.modal.toLowerCase() }} ? -->
            </v-card-text>
            <v-card-text v-if="table.modal == 'assign student to class'">
              {{
                $t(
                  "Are you sure you want to permanently remove this student from this class?"
                )
              }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                class="modal-btn-cancel noBtn"
                @click="dialog = false"
                id="noBtn"
                ref="always_focus"
                >{{ $t("No") }}</v-btn
              >
              <v-btn
                id="yesBtn"
                @click="deleteItem(deletedItem)"
                class="modal-btn-save yesBtn"
                >{{ $t("Yes") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- bulk reset user passwords -->
        <v-dialog
          v-model="dialogReset"
          persistent
          max-width="300"
          class="dialog_confirm"
        >
          <v-card>
            <v-card-title class="headline">{{
              $t("Confirmation Message")
            }}</v-card-title>

            <v-card-text>{{
              $t("Are you sure you want to reset the user’s passwords?")
            }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                class="modal-btn-cancel noBtn"
                @click="dialogReset = false"
                id="noBtn"
                ref="always_focus"
                >{{ $t("No") }}</v-btn
              >
              <v-btn
                id="yesBtn"
                @click="bulkResetPasswords()"
                class="modal-btn-save yesBtn"
                >{{ $t("Ye") }}s</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- /bulk reset user passwords -->

        <v-dialog
          v-model="bulkDialog"
          persistent
          max-width="300"
          class="dialog_confirm"
        >
          <v-card>
            <v-card-title class="headline">{{
              $t("Confirmation Message")
            }}</v-card-title>

            <v-card-text>
              {{
                $t("Are you sure you want to permanently remove these students")
              }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                class="modal-btn-cancel noBtn2"
                @click="bulkDialog = false"
                >{{ $t("No") }}</v-btn
              >
              <v-btn @click="bulkDelete()" class="modal-btn-save yesBtn2">{{
                $t("Yes")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="bulkApproveDialog"
          persistent
          max-width="300"
          class="dialog_confirm"
        >
          <v-card>
            <v-card-title class="headline">{{
              $t("Confirmation Message")
            }}</v-card-title>

            <v-card-text>{{
              $t("Are you sure you want to approve these students")
            }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                class="modal-btn-cancel noBtn2"
                @click="bulkApproveDialog = false"
                >{{ $t("No") }}</v-btn
              >
              <v-btn
                :loading="loading"
                :disabled="loading"
                @click="bulkApprove()"
                class="modal-btn-save yesBtn2"
                >{{ $t("Yes") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="copyExamDialog"
          persistent
          max-width="600"
          class="dialog"
        >
          <v-card>
            <v-card-title class="headline">{{
              $t("Copy exam to class in the same grade")
            }}</v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" class="mt-5">
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <div>
                        <label>{{ $t("Copy to Class") }}</label>
                        <v-select
                          :items="classesInSameGrade"
                          item-text="name"
                          item-value="id"
                          v-model.trim="copyExamClassId"
                          :rules="[validationRules.required]"
                          solo
                        >
                        </v-select>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="modal-btn-cancel noBtn2"
                @click="copyExamDialog = false"
                >{{ $t("Cancel") }}</v-btn
              >
              <v-btn
                :loading="loading"
                :disabled="copyExamClassId == null"
                @click="copyExam()"
                class="modal-btn-save yesBtn2"
                >{{ $t("Copy Exam") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span
        class="white--text"
        v-if="table.modal != 'assign student to class'"
        >{{ snackText }}</span
      >
      <span
        class="white--text"
        v-if="table.modal == 'assign student to class'"
        >{{ assignStudentSnacktxt }}</span
      >

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
    <AddEditPlanFees
      v-if="table.modal == 'Fee Plan'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></AddEditPlanFees>

    <addEditItemCategoryComponent
      v-if="table.modal == 'Item Category'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditItemCategoryComponent>

    <addEditLibraryItems
      v-if="table.modal == 'Library' || table.modal == 'My Library'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditLibraryItems>

    <addEditDiscussionRoom
      v-if="table.modal == 'Discussion Room'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditDiscussionRoom>

    <addEditItemComponent
      v-if="table.modal == 'Item'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditItemComponent>

    <addEditSubjectTypeComponent
      v-if="table.modal == 'Subject Type'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditSubjectTypeComponent>

    <addEditQualifierComponent
      v-if="table.modal == 'Qualifier'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditQualifierComponent>

    <addEditSubjectComponent
      v-if="table.modal == 'Subject'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditSubjectComponent>

    <addEditGradeComponent
      v-if="table.modal == 'Grade'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditGradeComponent>

    <addEditClassesComponent
      v-if="table.modal == 'Class'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
      :academic_grade_id="this.table.academic_grade_id"
    ></addEditClassesComponent>

    <addEditUserComponent
      v-if="table.modal == 'User'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditUserComponent>

    <addEditBooksComponent
      v-if="table.modal == 'Book'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
      :academic_grade_id="this.table.academic_grade_id"
    ></addEditBooksComponent>

    <AddEditDiscountPackages
      v-if="table.modal == 'DiscountPackage'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + 'Discount'"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></AddEditDiscountPackages>

    <importComponent
      v-if="
        table.modal == 'Grade' ||
        table.modal == 'Item' ||
        table.modal == 'Student' ||
        table.modal == 'Teacher'
      "
      :ImportDialog="ImportDialog"
      :formTitle="table.modal"
      v-on:childImportToParent="onChildImportClick"
    ></importComponent>

    <addEditTaxComponent
      v-if="table.modal == 'Tax'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditTaxComponent>

    <AddEditCashPointComponents
      v-if="table.modal == 'Cash Point'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></AddEditCashPointComponents>

    <AddEditPartnersComponents
      v-if="table.modal == 'Partner'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></AddEditPartnersComponents>

    <changePasswordComponent
      v-if="table.modal == 'User'"
      :UserId="table.editedItem.id"
      :ChangePasswordDialog="ChangePasswordDialog"
      v-on:childToParentPassword="onChildPasswordClick"
    ></changePasswordComponent>

    <AssignStudentToClass
      v-if="table.modal == 'assign student to class'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      :items="items"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></AssignStudentToClass>

    <addEditExamComponent
      v-if="table.modal == 'Exam'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditExamComponent>

    <addEditTeacherComponent
      v-if="table.modal == 'Teacher'"
      :editedItem="table.editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' ' + table.modal"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.table.apiEndPoints"
    ></addEditTeacherComponent>

    <!-- print exam results modal in exams tabs -->
    <PrintExamResultModal
      v-if="table.modal == 'Exam'"
      :editedItem="table.editedItem"
      :printDialog="printExamsDialog"
      v-on:childToParent="onPrintExamsCancel"
      :editedIndex="editedIndex"
    ></PrintExamResultModal>

    <PromoteStudent
      v-if="table.modal == 'assign student to class'"
      :promoteStudentsDialog="promoteStudentsDialog"
      :selected="selected"
      v-on:childToParent="onPromoteStudentsDialogCancel"
    ></PromoteStudent>
    <BroadCastComponent
      :BroadCastDialog="BroadCastDialog"
      :students_ids="students_ids"
      v-on:childToParent="onChildBroadCastClick"
    ></BroadCastComponent>

    <bookTransactionComponent
      v-if="table.modal == 'Books List'"
      :bookTransactionDialog="bookTransactionDialog"
      :grade_id="filter.booksListGrade"
      :books="books"
      :students="selected"
      :selectedStudent="student"
      v-on:childToParent="OnchildParentTransactionClick"
    ></bookTransactionComponent>

    <AddStudentPackageComponent
      v-if="table.modal == 'Student'"
      :PackageDialog="PackageDialog"
      :student_id="student_id"
      v-on:childToParent="OnchildParentPackageClick"
    ></AddStudentPackageComponent>
    <!--print dialog-->
    <v-dialog v-model="printDialog" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Print Students List") }}</span>

          <v-spacer></v-spacer>

          <v-icon class="main-color" @click="ClosePrintDialog"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="buttons-space">
              <v-col md="6" sm="6">
                <v-btn class="modal-btn-import" @click="printStudentsList">{{
                  $t("Students List")
                }}</v-btn>
              </v-col>
              <v-col md="6" sm="6">
                <v-btn
                  class="modal-btn-download"
                  @click="printParentsMeeting"
                  >{{ $t("Parents Meeting") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addCategoryModal" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Add Category") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Name") }}</label>
                  <v-text-field
                    v-model.trim="categoryItem.name"
                    :rules="[validationRules.required]"
                    solo
                  ></v-text-field>
                  <!-- <div v-if="validation_errors.name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                  </div>-->
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <label>{{ $t("Icon") }}</label>

                  <v-file-input
                    @change="readFiles"
                    :label="$t('Icon')"
                    chips
                  ></v-file-input>
                  <!-- :rules="[validationRules.required]" -->

                  <div v-if="validation_errors.file">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.file"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="6" md="6" sm="12" v-if="categoryItem.icon">
                  <div class="circle">
                    <img class="category" :src="categoryItem.icon" />
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="addCategoryModal = false">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            v-if="categoryItem.id && categoryItem.id != ''"
            class="modal-btn-save"
            @click="updateCategory(categoryItem)"
            :disabled="loading"
            :loading="loading"
            >{{ $t("Save") }}</v-btn
          >
          <v-btn
            class="modal-btn-save"
            @click="AddNewCategory"
            :disabled="loading"
            :loading="loading"
            v-else
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show More Weeks Library -->
    <v-dialog v-model="modalAcadimceWeeks" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5" style="float: right">
          <v-icon large :title="$t('Close')" @click="modalAcadimceWeeks = false"
            >close</v-icon
          >
        </v-card-title>

        <v-card-text>
          <v-chip v-for="week in weeksLibrary" class="ma-2 main-color">
            {{ week }}
          </v-chip>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import colors from "vuetify/lib/util/colors";
import axios from "axios";
import AddStudentPackageComponent from "./modals/AddStudentPackageComponent";
import addEditItemCategoryComponent from "./modals/addEditItemCategoryComponent";
import addEditLibraryItems from "./modals/addEditLibraryItems";
import addEditDiscussionRoom from "./modals/addEditDiscussionRoom";
import addEditItemComponent from "./modals/addEditItemComponent";
import addEditSubjectTypeComponent from "./modals/addEditSubjectTypeComponent";
import addEditQualifierComponent from "./modals/addEditQualifierComponent";
import addEditSubjectComponent from "./modals/addEditSubjectComponent";
import addEditGradeComponent from "./modals/addEditGradeComponent";
import importComponent from "./modals/importComponent";
import AddEditPlanFees from "./modals/AddPlanFeesModal";
import addEditClassesComponent from "./modals/addEditClassesComponent";
import addEditUserComponent from "./modals/addEditUserComponent";
import addEditBooksComponent from "./modals/addEditBooksComponent";
import addEditTaxComponent from "./modals/addEditTaxComponent";
import AddEditCashPointComponents from "./modals/addEditCashPointsComponent";
import AddEditPartnersComponents from "./modals/addEditPartnersComponents";
import changePasswordComponent from "./modals/changePasswordComponent";
import AssignStudentToClass from "./modals/AssignStudentToClass";
import addEditExamComponent from "./modals/addEditExamComponent";
import addEditTeacherComponent from "./modals/addEditTeacherComponent";
import PrintExamResultModal from "./modals/printExamResultsModal";
import PromoteStudent from "./modals/PromoteStudent";
import BroadCastComponent from "./modals/broadCastComponent";
import bookTransactionComponent from "./modals/bookTransactionComponent";
import AddEditDiscountPackages from "./modals/AddEditDiscountPackages";
import StudentHoverMenu from "./Students/StudentHoverMenu";
import ACL from "../acl";
import { validationMixin } from "../mixins/validationMixin";

export default {
  props: ["table", "selectedChild"],
  name: "DataTableComponents",
  data() {
    return {
      activitylogPermission: ACL.checkPermission("activitylog"),
      partnerGrops: [],
      PackageDialog: false,
      student_id: "",
      valid: false,
      bookTransactionDialog: false,
      NotShowZero: true,
      loginAs: localStorage.loginAs,
      user_id: localStorage.id,
      bulkApproveDialog: false,
      isSelected: 0,
      printDialog: false,
      exportTitle: "",
      showSelect: false,
      ChangePasswordDialog: false,
      allwarehouses: [],
      createdBy: [],
      filterMenu: false,
      ImportDialog: false,
      formTitle: "Add",
      admissionLoaded: false,
      snack: false,
      snackColor: "",
      snackText: "",
      assignStudentSnacktxt: this.$i18n.t(
        "The selected student has been removed successfully"
      ),
      weeksLibrary: [],
      modalAcadimceWeeks: false,
      snackTime: 3000,
      dialogBulkMessage: "",
      dialog: false,
      bulkDialog: false,
      dialogBulk: false,
      dialogReset: false,
      deletedItem: [],
      model: null,
      grades: [],
      classesInGrade: [],
      getAllpartners: [],
      getAllPaymentpartners: [],
      getCashiers: [],
      classes: [],
      items: [],
      loading: false,
      qualifiers: [],

      options: {
        itemsPerPage: 15,
      },
      categories: [],
      categoryItem: {
        name: "",
        image: "",
      },
      validation_errors: [],
      warehouse_item_id: "",
      //pagination options
      page: 1,
      circle: true,
      length: 0,
      totalVisible: 3,
      // end pagination
      selected: [],
      // action menus
      actionMenus: [
        { title: this.$i18n.t("Edit"), icon: "edit" },
        { title: this.$i18n.t("Delete"), icon: "delete" },
      ],
      search: "",
      no_search: "",
      totalItems: 0,
      /*dialog add and edit*/
      OpenDialog: false,
      copyExamDialog: false,
      copyExamId: null,
      classesInSameGrade: [],
      copyExamClassId: null,
      AddEditdialog: false,
      printExamsDialog: false,
      addCategoryModal: false,

      /* for promote student Dialog */
      promoteStudentsDialog: false,

      editedIndex: -1,
      size: 3,
      characters: "a-z,A-Z,0-9,#",
      files: [],
      years: [],
      /* filter*/
      filter: {
        academic_weeks_id: [],
        currentWeek: false,
        countries: [],
        roles: [],
        grades: [],
        classes: [],
        gender: [],
        status_student: [],
        subjects: [],
        quarters: [],
        quarter: "",
        qualifiers: [],
        academic_periods: [],
        genders: [],
        qualifiers_ids: [],
        academic_periods_ids: [],
        subject_ids: [],
        status: [],
        types: [],
        categories: [],
        transaction_date_from: "",
        transaction_date_to: "",
        warehouses: [],
        createdby: "",
        partners: [],
        created_by_ids: [],
        booksListGrade: "",
        quantity_from: "",
        quantity_to: "",
        warehouses_ids: [],
        cashiers: [],
        files: "",
        assign: "",
        payment_status: [],
        year_id: [],
      },
      qualifierList: [],
      subjectsList: [],
      academicList: [],
      nationalities: [],
      studentsbookListGrades: [],
      itemsCategory: [],

      /* for parent Dashbaord */
      childs: [],
      // selectedChild: "",

      roles: [],
      editBtnVisible:
        !ACL.Role("Student") &&
        ACL.checkPermission(this.table.permissions.edit),
      refundBtnVisible:
        !ACL.Role("Student") &&
        ACL.checkPermission(this.table.permissions.refund),
      deleteBtnVisible:
        !ACL.Role("Student") &&
        ACL.checkPermission(this.table.permissions.delete),
      changePasswordBtnVisible:
        !ACL.Role("Student") &&
        ACL.checkPermission(this.table.permissions.change_password),
      viewBtnVisible: ACL.checkPermission(this.table.permissions.show),
      // assignClassBtnVisible: ACL.checkPermission(
      //   this.table.permissions.assign_to_class
      // ),
      printBtnVisible: ACL.checkPermission(this.table.permissions.print),
      exportBtnVisible: ACL.checkPermission(this.table.permissions.export),
      importBtnVisible: ACL.checkPermission(this.table.permissions.import),
      LoginAsBtnVisible: ACL.checkPermission("login-as-user"),
      listExamMarksVisible: ACL.checkPermission("list-student-exam-marks"),
      viewPassword: ACL.checkPermission(this.table.permissions.viewPassword),
      promoteStudentsPermission: ACL.checkPermission("list-exams"),
      approveBtnVisible: ACL.checkPermission("bulk-approve-students"),
      canControlCategories: ACL.Role("Super-Admin") || ACL.Role("Teacher"),
      viewtransaction: ACL.checkPermission("view-transactions"),
      upgradeBtnVisible: ACL.checkPermission("student-upgrade"),
      archiveStudentBtnVisible: ACL.checkPermission("archive-students"),
      AssignDiscountPackageBtnVisible: ACL.checkPermission(
        "assign-discount-package"
      ),
      DeassignDiscountPackageBtnVisible: ACL.checkPermission(
        "deassign-discount-package"
      ),
      copyExamBtnVisible: ACL.checkPermission("copy-online-exam"),
      BroadCastDialog: false,
      students_ids: [],
      books: [],
      invoiceGrades: [],
      student: null,
      allQuarters: [],
    };
  },
  mixins: [validationMixin],
  components: {
    AddStudentPackageComponent,
    bookTransactionComponent,
    addEditItemCategoryComponent,
    addEditLibraryItems,
    addEditDiscussionRoom,
    addEditItemComponent,
    addEditSubjectTypeComponent,
    addEditQualifierComponent,
    addEditSubjectComponent,
    addEditGradeComponent,
    importComponent,
    AddEditPlanFees,
    addEditClassesComponent,
    addEditUserComponent,
    addEditBooksComponent,
    addEditTaxComponent,
    AddEditCashPointComponents,
    AddEditPartnersComponents,
    changePasswordComponent,
    AssignStudentToClass,
    addEditExamComponent,
    PrintExamResultModal,
    addEditTeacherComponent,
    PromoteStudent,
    BroadCastComponent,
    StudentHoverMenu,
    AddEditDiscountPackages,
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.getDataFromApi(this.$route.query.page);
        } else {
          this.getDataFromApi(1);
        }
        // let pageNumber = this.$router.currentRoute.params.pageNumber;
        // let tabNumber = this.$router.currentRoute.params.tabNumber;
      },
      deep: true,
    },
    page: function () {
      let tabNumber = 0;
      if (this.table.modal == "All Exam Results") {
        this.getDataFromApi(this.page);
      }

      if (this.$route.query.tab !== undefined) {
        tabNumber = Number(this.$route.query.tab);
      }
      if (this.$router.currentRoute.params.item_id !== undefined) {
        this.warehouse_item_id = Number(
          this.$router.currentRoute.params.item_id
        );
      }
      if (
        this.table.modal == "Payment" ||
        this.table.modal == "GeneralPayment"
      ) {
        // if (tabNumber == 0) {
        this.$router.push(
          {
            path: "/" + "payments" + "?tab=" + tabNumber + "&page=" + this.page,
          },
          () => {}
        );
        // } else if (tabNumber == 1) {
        //   this.$router.push(
        //     {
        //       path: "/" + "payments" + "?tab=1&page=" + this.page
        //     },
        //     () => {}
        //   );
        // }
      } else if (this.table.modal == "Class" || this.table.modal == "Book") {
        let gradeId = this.$router.currentRoute.params.grade_year_id;

        if (tabNumber == 0) {
          this.$router.push(
            {
              path: "/" + "gradeyears/" + gradeId + "?tab=0&page=" + this.page,
            },
            () => {}
          );
        } else if (tabNumber == 2) {
          this.$router.push(
            {
              path: "/" + "gradeyears/" + gradeId + "?tab=2&page=" + this.page,
            },
            () => {}
          );
        }
      } else if (
        this.table.modal == "assign student to class" ||
        this.table.modal == "Exam"
      ) {
        let academicId = this.$router.currentRoute.params.academicYear;
        let classId = this.$router.currentRoute.params.classId;

        // if (this.table.modal == "assign student to class") {
        this.$router.push(
          {
            path:
              "/" +
              "manageclass/" +
              academicId +
              "/" +
              classId +
              "?tab=" +
              this.$route.query.tab +
              "&page=" +
              this.page,
          },
          () => {}
        );
      } else if (this.table.modal == "Warehouse Transactin") {
        if (this.$route.query.item_id !== undefined) {
          this.warehouse_item_id = Number(this.$route.query.item_id);
          this.$router.push(
            {
              path:
                "/" +
                this.table.path +
                "?page=" +
                this.page +
                "&item_id=" +
                this.warehouse_item_id,
            },
            () => {}
          );
        } else {
          this.$router.push(
            {
              path: "/" + this.table.path + "?page=" + this.page,
            },
            () => {}
          );
        }
      } else {
        if (
          this.table.modal != "All Exam Results for parentDashboard" &&
          this.table.modal != "All Exam Results"
        ) {
          if (this.table.path == "students" && this.$route.query.unAssign) {
            this.$router.push(
              {
                path:
                  "/" +
                  this.table.path +
                  "?page=" +
                  this.page +
                  "&unAssign=true&active_year=" +
                  this.$route.query.active_year,
              },
              () => {}
            );
          } else {
            this.$router.push(
              {
                path: "/" + this.table.path + "?page=" + this.page,
              },
              () => {}
            );
          }
        } else {
          this.getDataFromApi(this.page);
          // this.$router.push(
          //   {
          //     path: "/" + "?page=" + this.page
          //   },
          //   () => {}
          // );
        }
      }
      // this.getDataFromApi(this.page);
    },
    options: {
      handler() {
        if (
          this.options.sortBy.length > 0 &&
          this.options.sortDesc.length > 0
        ) {
          this.getDataFromApi(this.page);
        }
      },
      deep: true,
    },
    selected: {
      handler() {
        // if (this.table.modal == "Invoice") {
        //   this.selected = this.selected.filter(function(item) {
        //     return item.status != "draft";
        //   });
        // }

        if (this.selected.length > 0)
          this.exportTitle = "Export Selected " + this.table.title;
        else this.exportTitle = "Export All " + this.table.title;
      },
      deep: true,
    },
    // search: {
    //   handler() {
    //     if (this.table.modal != "ApplicantLogs") {
    //       this.page = 1;
    //       this.getDataFromApi(this.page);
    //     }
    //   },
    //   deep: true
    // },
    filter: {
      handler() {
        this.page = 1;
        this.getDataFromApi(this.page);
      },
      deep: true,
    },
    selectedChild: {
      handler() {
        if (this.childs.length > 0) {
          this.getDataFromApi(this.page);
          this.getFiltersData();
        }
      },
    },
    // dialog: {
    //   handler() {
    //     if (this.dialog == true) {
    //       this.autoFocusDialog();
    //     }
    //   },
    // },
    // bulkDialog: {
    //   handler() {
    //     if (this.bulkDialog == true) {
    //       this.autoFocusBulkDialog();
    //     }
    //   },
    // },
  },
  computed: {},
  methods: {
    getActivityLogLink() {
      if (this.table.modal == "User") {
        return "/systemlogging/users";
      } else if (this.table.modal == "Student") {
        return "/systemlogging/students";
      } else if (this.table.modal == "Subject Type") {
        return "/systemlogging/subject_type";
      } else if (this.table.modal == "Grade") {
        return "/systemlogging/grade";
      } else if (this.table.modal == "Subject") {
        return "/systemlogging/Subject";
      } else if (this.table.modal == "Qualifier") {
        return "/systemlogging/Qualifier";
      } else if (this.table.modal == "Teacher") {
        return "/systemlogging/teachers";
      } else if (this.table.modal == "Tax") {
        return "/systemlogging/taxes";
      } else if (this.table.modal == "Cash Point") {
        return "/systemlogging/cash_point";
      } else if (this.table.modal == "Payment") {
        return "/systemlogging/internal_payment";
      } else if (this.table.modal == "Partner") {
        return "/systemlogging/partner";
      } else if (this.table.modal == "Library") {
        return "/systemlogging/digital_library";
      } else if (this.table.modal == "Invoice") {
        return "/systemlogging/invoices";
      } else {
        return "#";
      }
    },
    OnchildParentPackageClick(val) {
      this.PackageDialog = val;
    },
    openPackageStudent(item) {
      this.student_id = item.id;
      this.PackageDialog = true;
    },
    openPaymentCreate() {
      this.$router.push(
        {
          path: "/generalPayment",
        },
        () => {}
      );
    },
    OnchildParentTransactionClick(value, selected) {
      this.bookTransactionDialog = value;
      this.student = null;
      this.selected = [];
    },
    openBookTransactionDialog(item = null) {
      if (this.filter.booksListGrade == "") {
        this.snack = true;
        this.snackColor = "red";
        if (this.currentAppLocale == "ar") {
          this.snackText = "من فضلك أختر الصف اولاُ";
        } else {
          this.snackText = "please select grade first";
        }
      } else {
        if (this.selected.length == 0 && item == null) {
          this.snack = true;
          this.snackColor = "red";
          if (this.currentAppLocale == "ar") {
            this.snackText = "من فضلك أختر الطالب اولاُ";
          } else {
            this.snackText = "please select students first";
          }
        } else {
          if (item != null) {
            this.student = item;
            this.selected = [];
          } else {
            this.student = null;
          }
          this.getBooks();
          this.bookTransactionDialog = true;
        }
      }
    },
    getBooks() {
      axios
        .post(
          this.getApiUrl +
            "/warehouses_transactions/BooksListQuantities/" +
            this.filter.booksListGrade,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.books = response.data.data;
        });
    },
    getAllCashiers() {
      axios
        .get(this.getApiUrl + "/payments/getCashiers", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.getCashiers = response.data.data;
        });
    },
    GetItemsWithoutZero() {
      this.getDataFromApi();
    },
    getGrades() {
      if (this.table.modal == "Books List") {
        axios
          .get(this.getApiUrl + "/warehouses/getGrades", {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.studentsbookListGrades = response.data.data;
          });
      } else {
        axios
          .get(this.getApiUrl + "/getGrades", {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.studentsbookListGrades = response.data.data.grades;
            // this.grades = response.data.data.grades;
          });
      }
    },
    getclassesInGrade(id) {
      // this.filter.booksListGrades;
      if (this.table.modal == "Books List") {
        axios
          .get(this.getApiUrl + "/warehouses/getclassesInGrade/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.classesInGrade = response.data.data;
          });
      }
    },
    deassignPackage(item) {
      axios
        .get(this.getApiUrl + "/students/deassignPackageToStudent/" + item.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.snack = true;
          this.snackText = "success";
          this.snackColor = "green";
        });
    },
    getStudentBookList() {
      let grade = this.filter.booksListGrade;
      this.getDataFromApi(1);
      this.getclassesInGrade(grade);
    },
    editWarehouseTransactin(id) {
      this.$router.push(
        "/warehouses_transactions/show/" + id + "?editMode=true"
      );
    },
    printTransaction(id) {
      axios
        .get(
          this.getApiUrl +
            "/warehouses_transactions/printWareHouseTransaction/" +
            id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          if (res.data.status.error == false) {
            let printLink = res.data.data;
            window.open(printLink, "_blank");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    printBookList(id) {
      axios
        .get(
          this.getApiUrl +
            "/warehouses_transactions/printBookListStudent/" +
            id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          if (res.data.status.error == false) {
            let printLink = res.data.data;
            window.open(printLink, "_blank");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    OpenNewWarehousTransaction() {
      this.$router.push({
        path: `/CreateWarehuseTransactions`,
      });
    },
    deImpersonate() {
      axios
        .get(this.getApiUrl + "/deImpersonate/" + localStorage.old_user, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            let id = response.data.data.user.id;
            let token = response.data.data.access_token;
            let school = response.data.data.school;
            let logo = response.data.data.logo;
            let school_name = response.data.data.school_name;
            let gradeClass = response.data.data.gradeClass;
            localStorage.user_name = response.data.data.user.name;
            // localStorage to store user cradentials
            localStorage.id = id;
            localStorage.email = response.data.data.user.email;
            localStorage.lastLogin = response.data.data.lastLogin;
            localStorage.login = true;
            localStorage.token = token;
            localStorage.school = school;
            localStorage.logo = logo;
            localStorage.school_name = school_name;
            localStorage.gradeClass = gradeClass;
            localStorage.expiry = new Date().getTime() + 86400000; // 24 hours
            // localStorage.expiry = new Date().getTime() + 60000; // 1 minute
            localStorage.permissionsObject = JSON.stringify(
              response.data.data.permissions
            );
            localStorage.menus = JSON.stringify(response.data.data.menus);
            localStorage.roles = JSON.stringify(response.data.data.roles);
            if (
              response.data.data.roles &&
              response.data.data.roles.length == 1
            ) {
              localStorage.selectedRole = response.data.data.roles[0];
            } else {
              localStorage.selectedRole = "";
            }
            localStorage.selectedSomeRole = false;
            localStorage.loginAs = response.data.data.login_as;
            if (localStorage.loginAs) {
              localStorage.old_user = response.data.data.old_user;
            }
            localStorage.updateData = response.data.data.updateData;
            this.$router.push({
              path: `/`,
            });
            location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loginAsUser(user) {
      this.loading = true;
      axios
        .get(this.getApiUrl + "/impersonate/" + user.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            let id = response.data.data.user.id;
            let token = response.data.data.access_token;
            let school = response.data.data.school;
            let logo = response.data.data.logo;
            let school_name = response.data.data.school_name;
            let gradeClass = response.data.data.gradeClass;
            localStorage.user_name = response.data.data.user.name;
            // localStorage to store user cradentials
            localStorage.id = id;
            localStorage.email = response.data.data.user.email;
            localStorage.lastLogin = response.data.data.lastLogin;
            localStorage.login = true;
            localStorage.token = token;
            localStorage.school = school;
            localStorage.logo = logo;
            localStorage.school_name = school_name;
            localStorage.gradeClass = gradeClass;
            localStorage.expiry = new Date().getTime() + 86400000; // 24 hours
            // localStorage.expiry = new Date().getTime() + 60000; // 1 minute
            localStorage.permissionsObject = JSON.stringify(
              response.data.data.permissions
            );
            localStorage.menus = JSON.stringify(response.data.data.menus);
            localStorage.roles = JSON.stringify(response.data.data.roles);
            localStorage.is_employee = response.data.data.is_employee;

            if (
              response.data.data.roles &&
              response.data.data.roles.length == 1
            ) {
              localStorage.selectedRole = response.data.data.roles[0];
            } else {
              localStorage.selectedRole = "";
            }
            localStorage.selectedSomeRole = false;
            localStorage.loginAs = response.data.data.login_as;
            if (localStorage.loginAs) {
              localStorage.old_user = response.data.data.old_user;
              localStorage.old_user_name = response.data.data.old_user_name;
            }
            localStorage.updateData = response.data.data.updateData;
            this.$router.push({
              path: `/`,
            });
            window.location = "/";
            // location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openBroadCastDialog() {
      this.students_ids = [];
      if (this.selected.length > 0) {
        let _this = this;
        this.selected.filter(function (item) {
          _this.students_ids.push(item.student_id);
        });
        this.BroadCastDialog = true;
      } else {
        this.snack = true;
        this.snackColor = "red";

        if (this.currentAppLocale == "ar") {
          this.snackText = "من فضلك أختر الطلاب اولاٌ";
        } else {
          this.snackText = "please select students first";
        }
      }
    },
    // autoFocusDialog() {
    //   $(".noBtn").focus();
    //   setTimeout(() => {
    //     $(".noBtn").focus();
    //   }, 100);
    // },
    openResetDialog() {
      if (this.selected.length > 0) {
        this.dialogReset = true;
      } else {
        this.snack = true;
        this.snackColor = "red";
        if (this.currentAppLocale == "ar") {
          this.snackText = "لم يتم تحديد مستخدم";
        } else {
          this.snackText = "No User Selected";
        }
      }
    },
    bulkResetPasswords() {
      axios
        .post(
          this.getApiUrl + "/users/bulkResetPassword",

          { items: this.selected },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.dialogReset = false;
            this.snack = true;
            this.snackColor = "green";
            if (this.currentAppLocale == "ar") {
              this.snackText = "تم إعادة تعيين كلمات مرور بنجاح";
            } else {
              this.snackText = "User Passwords Reset Successfully";
            }
            location.reload();
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    openCategoryModal() {
      this.categoryItem.id = "";
      this.categoryItem.name = "";
      this.addCategoryModal = true;
    },
    getSubjectsUnderGrade() {
      axios
        .get(
          this.getApiUrl +
            "/elibrary/getSubjectsUnderGrade?grade_id=" +
            this.filter.grades,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.table.filters.subjects = response.data.data.subjects;
        });
    },
    readFiles(event) {
      this.categoryItem.image = event;
    },
    AddNewCategory() {
      if (this.valid == false) {
        this.$refs.form.validate();
      } else {
        const formData = new FormData();
        if (this.categoryItem.image) {
          formData.append("file", this.categoryItem.image);
        }

        formData.append("name", this.categoryItem.name);

        this.loading = true;
        axios
          .post(this.getApiUrl + "/elibrary/categories/store", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == true) {
              Object.assign(
                (this.validation_errors =
                  response.data.status.validation_errors)
              );
            } else {
              if (!response.data.status.error) {
                this.addCategoryModal = false;
                this.getCategories();
                location.reload();
              }
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    },

    getCategories() {
      axios
        .get(this.getApiUrl + "/elibrary/categories", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (!response.data.status.error) {
            this.categories = response.data.data;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    editCategory(category) {
      this.categoryItem.id = category.id;
      this.categoryItem.name = category.name;
      this.categoryItem.icon = category.icon;
      this.addCategoryModal = true;
    },

    updateCategory(category) {
      if (this.valid == false) {
        this.$refs.form.validate();
      } else {
        const formData = new FormData();
        if (this.categoryItem.image) {
          formData.append("file", this.categoryItem.image);
        }

        formData.append("name", this.categoryItem.name);

        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/elibrary/categories/" + category.id + "/update",
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == true) {
              Object.assign(
                (this.validation_errors =
                  response.data.status.validation_errors)
              );
            } else {
              if (!response.data.status.error) {
                this.getCategories();
                this.addCategoryModal = false;
                location.reload();
              }
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    deleteCategory(id, index) {
      axios
        .get(this.getApiUrl + "/elibrary/categories/" + id + "/destroy", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (!response.data.status.error) {
            this.categories.splice(index, 1);
            location.reload();
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = response.data.status.message;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    // autoFocusBulkDialog() {
    //   $(".noBtn2").focus();
    //   setTimeout(() => {
    //     $(".noBtn2").focus();
    //   }, 100);
    // },
    // autoFocusModals() {
    //   let _this = this;
    //   $(document).ready(function() {
    //     $(document).keydown(function(e) {
    //       let confirmDialog = _this.dialog;
    //       let bulkConfirmDialog = _this.bulkDialog;
    //       if (confirmDialog == true) {
    //         // left click
    //         if (e.keyCode == 37) {
    //           $(".noBtn").focus();
    //           setTimeout(() => {
    //             $(".noBtn").focus();
    //           }, 100);
    //         }
    //         // right click
    //         if (e.keyCode == 39) {
    //           $(".yesBtn").focus();
    //           setTimeout(() => {
    //             $(".yesBtn").focus();
    //           }, 100);
    //         }
    //       }
    //       if (bulkConfirmDialog == true) {
    //         // left click
    //         if (e.keyCode == 37) {
    //           $(".noBtn2").focus();
    //           setTimeout(() => {
    //             $(".noBtn2").focus();
    //           }, 100);
    //         }
    //         // right click
    //         if (e.keyCode == 39) {
    //           $(".yesBtn2").focus();
    //           setTimeout(() => {
    //             $(".yesBtn2").focus();
    //           }, 100);
    //         }
    //       }
    //     });
    //   });
    // },

    refund(item) {
      if (this.table.modal == "Payment") {
        this.$router.push({
          path: `/payments/refund/` + item.id,
        });
      } else {
        this.$router.push({
          path: `/reversegeneralPayment/` + item.id,
        });
      }
    },
    InitializeDatePicker() {
      let _this = this;

      setTimeout(() => {
        $("#transaction_date_from").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filter.transaction_date_from = dateH;
            } else {
              _this.filter.transaction_date_from = "";
            }
          },
        });

        $("#transaction_date_to").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filter.transaction_date_to = dateH;
            } else {
              _this.filter.transaction_date_to = "";
            }
          },
        });
      }, 1000);
    },
    IntializeDatePickerWareHouse() {
      let _this = this;
      setTimeout(() => {
        $("#warehoustransaction_date_from").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filter.transaction_date_from = dateH;
            } else {
              _this.filter.transaction_date_from = "";
            }
          },
        });

        $("#warehoustransaction_date_to").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var edate =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filter.transaction_date_to = edate;
            } else {
              _this.filter.transaction_date_to = "";
            }
          },
        });
      });
    },
    customRowClass(item) {
      return item.status == "deleted" ? "custom-bg" : "";
    },
    //get kids for parent on parent dashbaord
    getParentChilds() {
      axios
        .get(this.getApiUrl + "/schedules/getParentChildren", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.childs = res.data.data;
            // this.selectedChild = res.data.data[0].id;

            if (this.childs.length > 0) {
              this.getFiltersData();
              this.getDataFromApi();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /* filters data for parent dashbaord */
    getFiltersData() {
      this.qualifierList = [];
      this.academicList = [];
      this.subjectsList = [];
      if (this.selectedChild != null && this.selectedChild != "") {
        axios
          .get(
            this.getApiUrl +
              "/students/getParentStudentMarks/" +
              this.selectedChild +
              "?all_data",
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            let data = response.data.data;
            let qualifiers = this.qualifierList;
            let academics = this.academicList;
            let subjects = this.subjectsList;

            data.forEach((item) => {
              let qobj = {
                qualifier_title: item.q_title,
                qualifier_id: item.qualifier_id,
              };
              let academicObj = {
                academic_name: item.academic_year_name,
                academic_id: item.academic_year_id,
              };
              let subObj = {
                subject_name: item.subject_name,
                subject_id: item.subject_id,
              };

              qualifiers.push(qobj);
              academics.push(academicObj);
              subjects.push(subObj);
            });

            this.qualifierList = qualifiers;
            this.academicList = academics;
            this.subjectsList = subjects;
          });
      }
    },

    /* open promotion student dialog for on selected student */
    openPromoteStudentsDialog(item) {
      this.promoteStudentsDialog = true;
      this.selected = [];
      this.selected.push(item);
    },

    /* bulk promote students to academic periods */
    bulkPromoteStudentsDialog() {
      this.promoteStudentsDialog = true;
    },

    onPromoteStudentsDialogCancel() {
      this.promoteStudentsDialog = false;
      this.selected = [];
    },
    openPrintDialog() {
      this.printDialog = true;
    },
    ClosePrintDialog() {
      this.printDialog = false;
    },
    printStudentsList() {
      axios
        .get(this.getApiUrl + "/" + this.table.apiEndPoints.printList, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            let printLink = response.data.data;
            window.open(printLink, "_blank");
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    printParentsMeeting() {
      axios
        .get(
          this.getApiUrl + "/" + this.table.apiEndPoints.printParentMeetings,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            let printLink = response.data.data;
            window.open(printLink, "_blank");
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    getQualifiersForFilter() {
      this.hideLabel = true;
      if (this.filter.subjects.length > 0) {
        axios
          .get(
            this.getApiUrl + "/exams/getQualifiers/" + this.filter.subjects,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.data) {
              this.qualifiers = response.data.data;
            }
          });
      } else {
        this.qualifiers = [];
        this.filter.qualifiers = [];
      }
    },
    view(item) {
      this.$router.push({
        path: `/students/` + item.student_id,
      });
    },
    viewPayment(item) {
      if (this.table.modal == "Payment") {
        this.$router.push({
          path: `/payments/` + item.id,
        });
      } else {
        if (item.refund) {
          this.$router.push({
            path: `/viewReverseGeneralPayment/` + item.id,
          });
        } else {
          this.$router.push({
            path: `/generalPayment/` + item.id,
          });
        }
      }
    },
    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";
      let CharacterSetEnglishC = "";
      let CharacterSetEnglishS = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSetEnglishC += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSetEnglishS += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      //   if (charactersArray.indexOf("#") >= 0) {
      //     CharacterSet += "![]{}()%&*$#^<>~@|";
      //   }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
        password += CharacterSetEnglishC.charAt(
          Math.floor(Math.random() * CharacterSetEnglishC.length)
        );
        password += CharacterSetEnglishS.charAt(
          Math.floor(Math.random() * CharacterSetEnglishS.length)
        );
      }
      this.table.editedItem.password = password;
    },
    exportData() {
      let url = "";
      if (this.table.modal == "Item") {
        url = this.getApiUrl + "/items/export";
      }
      if (this.table.modal == "Student") {
        url = this.getApiUrl + "/students/export";
      }
      if (this.table.modal == "Invoice") {
        url = this.getApiUrl + "/invoices/export";
      }
      if (this.table.modal == "Payment") {
        url = this.getApiUrl + "/payments/export";
      }
      if (this.table.modal == "GeneralPayment") {
        url = this.getApiUrl + "/general_payment/export";
      }
      if (this.selected.length > 0) {
        // export selected
        axios
          .post(
            url,

            { items: this.selected },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              let exportLink = response.data.data.link;
              window.open(exportLink, "_blank");
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        // export all
        axios
          .post(
            url + "?search=" + this.search,
            {},
            {
              params: {
                filter: this.filter,
              },
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              let exportLink = response.data.data.link;
              window.open(exportLink, "_blank");
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    printPage(planId) {
      axios
        .get(this.getApiUrl + "/paymentplans/printPdfFee/" + planId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
    printPageStudent(studentId) {
      axios
        .get(this.getApiUrl + "/students/printPdfStudent/" + studentId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
    OpenDialogImport() {
      this.ImportDialog = true;
    },
    showPasswordDialog(item) {
      this.table.editedItem = Object.assign({}, item);
      this.ChangePasswordDialog = true;
    },
    onChildImportClick(value) {
      this.ImportDialog = value;
      this.getDataFromApi(this.page);
    },
    onChildPasswordClick(
      value,
      snack = null,
      snackColor = null,
      snackText = null
    ) {
      this.ChangePasswordDialog = value;
      this.snack = snack;
      this.snackColor = snackColor;
      this.snackText = snackText;
    },
    onChildClick(value, item, index) {
      this.AddEditdialog = value;
      if (item != null && index == -1) {
        this.items.push(item);
        this.totalItems = this.totalItems + 1;
        /* update table pagination */
        this.length = Math.ceil(this.totalItems / this.options.itemsPerPage);
        if (this.page < this.length) {
          this.page = Number(this.length);
        }
      } else if (index > -1)
        if (item != null) Object.assign(this.items[index], item);
      if (value == false) {
        // when close
        this.items = [];
        this.$nextTick(function () {
          // DOM is now updated
          // `this` is bound to the current instance
          // this.$forceUpdate();
          if (this.table.modal == "Library") {
            this.getDataFromApi(1);
          } else {
            this.getDataFromApi(this.page);
          }
        });
      }
    },
    onChildBroadCastClick() {
      this.BroadCastDialog = false;
      // this.snack = true;
      // this.snackColor = "green";
      // this.snackText = "success";
    },
    CurrentWeek() {
      if (this.filter.currentWeek == true) {
        this.filter.academic_weeks_id = "";
      }
    },
    SelectWeek() {
      this.hideLabel = true;
      this.filter.currentWeek = false;
    },
    OpenDialogAddEdit() {
      this.editedIndex = -1;
      // this.table.editedItem = {};
      if (this.table.editedItem.en) {
        Object.keys(this.table.editedItem.en).forEach(
          (k) => (this.table.editedItem.en[k] = "")
        );
        Object.keys(this.table.editedItem).forEach((k) => {
          if (k != "en" && k != "ar") this.table.editedItem[k] = "";
        });
      } else {
        if (this.table.modal != "User") {
          if (this.table.modal == "Cash Point") {
            this.table.editedItem = {
              id: "",
              name: "",
              type: "",
              code: "",
              currency: "SAR",
              payment_method: [],
              active: "",
              users: [],
            };
          } else if (this.table.modal == "Partner") {
            this.table.editedItem = {
              name: "",
              group_id: "",
              type: "",
              country_code_id: 187,
              mobile: "",
              email: "",
              notes: "",
            };
          } else if (this.table.modal == "Library") {
            this.table.editedItem = {
              filename: "",
              type: "",
              category_id: 1,
              grade_id: "",
              subject_id: "",
              url: "",
            };
          } else {
            Object.keys(this.table.editedItem).forEach(
              (k) => (this.table.editedItem[k] = "")
            );
          }
        } else {
          this.table.editedItem = {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            mobile: "",
            date_of_birth: "",
            country_id: 187,
            roles_ids: [],
            grade_ids: [],
            country_code_id: 187,
          };
          this.generate();
        }
        if (this.table.modal == "Teacher") {
          this.table.editedItem = {
            first_name: "",
            last_name: "",
            email: "",
            mobile: "",
            country_code_id: 187,
            grades_id: [],
            subjects_id: [],
          };
        }
      }
      if (this.table.editedItem.ar) {
        Object.keys(this.table.editedItem.ar).forEach(
          (k) => (this.table.editedItem.ar[k] = "")
        );
      }
      this.formTitle = "Add";
      this.AddEditdialog = true;
      var _this = this;
      $(document).ready(function () {
        $("#gregorian").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.table.editedItem.date_of_birth = dateH;
            } else {
              _this.table.editedItem.date_of_birth = "";
            }
          },
        });
      });
      $(document).ready(function () {
        $("#exam_date").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var edate =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.table.editedItem.exam_date = edate;
            } else {
              _this.table.editedItem.exam_date = "";
            }
          },
        });
      });
    },
    setPaginationParameters(response, search = false) {
      if (this.table.modal == "assign student to class") {
        if (search) {
          this.page = response.data.data.students.current_page;
        }

        this.length = response.data.data.students.last_page;
        if (this.length >= 5) this.totalVisible = 5;
      } else {
        if (search) {
          this.page = response.data.data.current_page;
        }

        this.length = response.data.data.last_page;
        if (this.length >= 5) this.totalVisible = 5;
      }
    },

    archiveStudent(item) {
      axios
        .get(this.getApiUrl + "/students/archive/" + item.student_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = res.data.status.message;
            this.getDataFromApi();
          } else {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = res.data.status.message;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    unarchiveStudent(item) {
      axios
        .get(this.getApiUrl + "/students/unarchive/" + item.student_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = res.data.status.message;
            this.getDataFromApi();
          } else {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = res.data.status.message;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showDialog(item) {
      this.dialog = true;
      this.deletedItem = item;
    },
    showCopyExamDialog(itemId) {
      this.copyExamId = itemId;
      this.copyExamDialog = true;
    },

    edit(item) {
      if (this.table.modal == "Student") {
        this.$router.push({
          path: `/students/` + item.student_id + "?editable=true",
        });
      }
      this.editedIndex = this.items.indexOf(item);
      this.table.editedItem = Object.assign({}, item);

      this.formTitle = "Edit";
      this.AddEditdialog = true;
      var _this = this;
      $(document).ready(function () {
        $("#gregorian").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.table.editedItem.date_of_birth = dateH;
            } else {
              _this.table.editedItem.date_of_birth = "";
            }
          },
        });
      });
      $(document).ready(function () {
        $("#exam_date").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var edate =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.table.editedItem.exam_date = edate;
            } else {
              _this.table.editedItem.exam_date = "";
            }
          },
        });
      });
    },

    deleteItem(item) {
      axios
        .get(
          this.getApiUrl + "/" + this.table.apiEndPoints.delete + "/" + item.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            const index = this.items.indexOf(item);
            if (this.table.modal != "Invoice") {
              this.items.splice(index, 1);
              /*update table pagination */
              this.totalItems = this.totalItems - 1;
              if (this.page < this.length) {
                this.getDataFromApi(this.page);
              }
              this.length = Math.ceil(
                this.totalItems / this.options.itemsPerPage
              );
              if (this.page > this.length) {
                this.page = Number(this.length);
              }
            } else {
              this.items.forEach((invoice) => {
                if (invoice.id == item.id) {
                  invoice.status = "deleted";
                }
              });
            }

            this.dialog = false;
            this.snack = true;
            this.snackColor = "success";

            let textSnack = this.table.modal;
            //textSnack.toLowerCase()
            this.snackText =
              this.$i18n.t("The selected ") +
              this.$i18n.t(textSnack) +
              this.$i18n.t(" has been removed successfully");

            this.assignStudentSnacktxt = this.$i18n.t(
              this.$i18n.t("The selected student has been removed successfully")
            );
          } else {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = response.data.status.message;
            this.assignStudentSnacktxt = response.data.status.message;
            this.dialog = false;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    getDataFromApi(page, search = false) {
      if (search) {
        page = 1;
        // this.$router.push("")
      }
      let onlyAssigned = "";
      let studentId = "";

      if (this.table.modal == "All Exam Results for parentDashboard") {
        studentId = "/" + this.selectedChild;
      }

      if (this.table.modal == "assign student to class") {
        onlyAssigned = "&only_assigned";
      }
      let warehouse_item_id = "";
      if (
        this.table.modal == "Warehouse Transactin" &&
        this.$router.currentRoute.params.item_id
      ) {
        warehouse_item_id =
          "&item_id=" + this.$router.currentRoute.params.item_id;
      }
      let sort_type = "";
      let column = "";
      if (this.options.sortBy.length > 0) {
        column = this.options.sortBy[0];
        sort_type = this.options.sortDesc[0] ? "DESC" : "ASC";
      }
      if (
        this.table.modal == "All Exam Results for parentDashboard" &&
        (studentId == null || studentId == "")
      ) {
        //
      } else {
        if (this.table.modal == "Books List") {
          if (this.filter.booksListGrade != "") {
            axios
              .get(
                this.getApiUrl +
                  "/" +
                  this.table.apiEndPoints.list +
                  studentId +
                  "?sort_type=" +
                  sort_type +
                  "&column=" +
                  column +
                  "&search=" +
                  this.search +
                  "&page=" +
                  page +
                  onlyAssigned,
                {
                  params: {
                    filter: this.filter,
                  },
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                this.setPaginationParameters(response, search);
                this.totalItems = response.data.data.total;
                this.items = [];
                this.items = response.data.data.data;
              });
          }
        } else {
          if (this.table.modal != "All Exam Results") {
            axios
              .get(
                this.getApiUrl +
                  "/" +
                  this.table.apiEndPoints.list +
                  studentId +
                  "?sort_type=" +
                  sort_type +
                  "&column=" +
                  column +
                  "&search=" +
                  this.search +
                  "&NotShowZero=" +
                  this.NotShowZero +
                  "&page=" +
                  page +
                  onlyAssigned +
                  warehouse_item_id,
                {
                  params: {
                    filter: this.filter,
                  },
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                this.setPaginationParameters(response, search);

                if (this.table.modal == "assign student to class") {
                  this.items = [];
                  this.items = response.data.data.students.data;
                  this.totalItems = response.data.data.students.total;
                  // alert("here");
                } else {
                  this.items = [];
                  this.items = response.data.data.data;
                  this.totalItems = response.data.data.total;
                }
              });
          }

          if (this.table.modal == "All Exam Results") {
            axios
              .get(
                this.getApiUrl +
                  "/students/getStudentMarksStudentDashboard?sort_type=" +
                  sort_type +
                  "&column=" +
                  column +
                  "&search=" +
                  this.search +
                  "&page=" +
                  page,
                {
                  params: {
                    filter: this.filter,
                  },
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                let data = response.data.data.data;
                let qualifiers = this.qualifierList;
                let academics = this.academicList;
                let subjects = this.subjectsList;
                this.items = response.data.data.data;
                this.setPaginationParameters(response, search);
                this.totalItems = response.data.data.total;

                data.forEach((item) => {
                  let qobj = {
                    qualifier_title: item.q_title,
                    qualifier_id: item.qualifier_id,
                  };
                  let academicObj = {
                    academic_name: item.academic_year_name,
                    academic_id: item.academic_year_id,
                  };

                  let subjectObj = {
                    subject_name: item.subject_name,
                    subject_id: item.subject_id,
                  };

                  qualifiers.push(qobj);
                  academics.push(academicObj);
                  subjects.push(subjectObj);
                });

                this.qualifierList = qualifiers;
                this.academicList = academics;
                this.subjectsList = subjects;
              });
          }
        }
      }
    },
    getWarehouses() {
      axios
        .get(this.getApiUrl + "/warehouses/getAllWarehouses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.allwarehouses = res.data.data;
            // this.getCreatedBy();
            // this.selectedChild = res.data.data[0].id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCreatedBy(id) {
      let item_id = "";
      if (this.$router.currentRoute.params.item_id !== undefined) {
        item_id =
          "?item_id=" + Number(this.$router.currentRoute.params.item_id);
      }
      axios
        .post(
          this.getApiUrl + "/warehouses/getAssignee" + item_id,
          { warehouses_ids: id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((res) => {
          if (res.data.status.error == false) {
            this.createdBy = res.data.data;
            // this.selectedChild = res.data.data[0].id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openModalAcadimceWeeks(weeks) {
      this.weeksLibrary = weeks;
      this.modalAcadimceWeeks = true;
    },
    getpartners() {
      let item_id = "";
      if (this.$router.currentRoute.params.item_id !== undefined) {
        item_id =
          "?item_id=" + Number(this.$router.currentRoute.params.item_id);
      }
      axios
        .get(this.getApiUrl + "/partners/getAllpartners" + item_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.getAllpartners = res.data.data;
            // this.selectedChild = res.data.data[0].id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getpartnersPayment() {
      if (this.table.modal == "Payment") {
        axios
          .get(this.getApiUrl + "/payments/getPartners", {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((res) => {
            if (res.data.status.error == false) {
              this.getAllPaymentpartners = res.data.data;
              // this.selectedChild = res.data.data[0].id;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .get(this.getApiUrl + "/partners/getPartnersNotStudentParent", {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((res) => {
            if (res.data.status.error == false) {
              this.getAllPaymentpartners = res.data.data;
              // this.selectedChild = res.data.data[0].id;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },
    // eslint-disable-next-line no-unused-vars
    goMaster(item) {
      let id = item.id;
      if (this.table.modal == "Fee Plan") {
        this.$router.push("/MasterFees/" + id);
      }
    },
    // this is related to tax
    arrayTostringAndUperCase(methods) {
      // let length = methods.length;
      // let str = "";
      // for (let index = 0; index < length - 1; index++) {
      //   str +=
      //     methods[index].charAt(0).toUpperCase() +
      //     methods[index].slice(1) +
      //     ", ";
      // }

      // str +=
      //   methods[length - 1].charAt(0).toUpperCase() +
      //   methods[length - 1].slice(1);
      return methods;
    },

    bulkDelete() {
      let selectedIds = [];
      this.selected.filter(function (item) {
        selectedIds.push(item.id);
      });

      axios
        .post(
          this.getApiUrl +
            "/students/bulkDeleteStudentFromClass/" +
            this.$router.currentRoute.params.classId,
          { ids: selectedIds },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.bulkDialog = false;
            this.getDataFromApi();
            this.selected = [];
          }
        });
    },
    bulkApprove() {
      let selectedIds = [];
      this.selected.filter(function (item) {
        selectedIds.push(item.student_id);
      });
      this.loading = true;

      axios
        .post(
          this.getApiUrl + "/students/bulkApproveStudents",
          { ids: selectedIds },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.bulkApproveDialog = false;
            this.getDataFromApi(this.page);
            this.selected = [];
            this.loading = false;
          } else {
            this.bulkApproveDialog = false;
            this.loading = false;
            this.snack = true;
            this.snackColor = "red";
            this.snackText = response.data.status.message;
          }
        });
    },
    openBulkDeleteDialog() {
      if (this.selected.length > 0) {
        this.bulkDialog = true;
      } else {
        alert("Please select students first");
      }
    },
    openBulkApproveDialog() {
      if (this.selected.length > 0) {
        this.bulkApproveDialog = true;
      } else {
        alert("Please select students first");
      }
    },

    // open printExams Dialog
    openPrintExamsDialog() {
      this.printExamsDialog = true;
    },
    onPrintExamsCancel() {
      this.printExamsDialog = false;
    },
    goToSchedule(item) {
      this.$router.push({
        path: `/teachers/schedule/` + item.id,
      });
    },
    openInvoice(item) {
      this.$router.push({
        path: `/invoices/` + item.id,
      });
    },
    copyExam() {
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/exams/copy/" + this.copyExamId,
          {
            class_id: this.copyExamClassId,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error) {
            this.snackColor = "red";
          } else {
            this.snackColor = "green";
            this.copyExamDialog = false;
          }
          this.snackText = response.data.status.message;
          this.snack = true;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.snackColor = "red";
          this.snackText = err.message;
          this.snack = true;
          this.loading = false;
        });
    },
    PrintSelectedInvoices() {
      if (this.selected.length == 0) {
        this.snack = true;
        this.snackText = "Please select invoices first";
        this.snackColor = "red";
      } else {
        let printSelected = this.selected.filter(function (item) {
          return item.status != "deleted";
        });
        axios
          .post(
            this.getApiUrl + "/invoices/printPdfAllInvoice",
            { invoices_ids: printSelected },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            var link = response.data.data;
            window.open(link, "_blank");
          });
      }
    },
    printInvoice(id) {
      axios
        .get(this.getApiUrl + "/invoices/printPdfInvoice/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
    PrintSelectedItems() {
      if (this.selected.length == 0) {
        this.snack = true;
        this.snackText = "Please select " + this.table.title + " first";
        this.snackColor = "red";
      } else {
        axios
          .post(
            this.getApiUrl + this.table.apiEndPoints.printAll,
            { items: this.selected },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            var link = response.data.data;
            window.open(link, "_blank");
          });
      }
    },
    PrintInvoiceTransactions(item) {
      if (item.transaction_ids.length > 0) {
        axios
          .post(
            this.getApiUrl +
              "/payments/printPdfAllPayments?invoice_screen=true",
            { items: item.transaction_ids },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            var link = response.data.data;
            window.open(link, "_blank");
          });
      }
    },
    printItem(id) {
      axios
        .get(this.getApiUrl + this.table.apiEndPoints.printPage + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
    editItem(id) {
      if (this.table.modal == "Payment") {
        this.$router.push(
          {
            path: "/editPayments/" + id,
          },
          () => {}
        );
      } else {
        this.$router.push(
          {
            path: "/editGeneralPayment/" + id,
          },
          () => {}
        );
      }
    },
    getClassesUnderGradeStudents(grade_id) {
      if (this.table.modal == "Student") {
        axios
          .get(
            this.getApiUrl +
              "/students/getClassesUnderGradeStudents/" +
              grade_id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.classes = response.data.data;
            this.hideLabel = true;
          });
      }
    },
    getAllClasses() {
      axios
        .get(this.getApiUrl + "/discussionboard/getClasses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.classes = response.data.data;
        });
    },
    getWarehouseCategories() {
      axios
        .get(this.getApiUrl + "/warehouses/warehouseCategories", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.categories = response.data.data;
        });
    },
  },

  showButtons() {},
  mounted() {
    if (this.table.modal == "Exam") {
      axios
        .get(
          this.getApiUrl +
            "/exams/grade/classes/" +
            this.$router.currentRoute.params.classId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.classesInSameGrade = response.data.data;
        });
    }

    if (
      this.table.modal == "All Exam Results" ||
      this.table.modal == "Exam" ||
      this.table.modal == "All Exam Results for parentDashboard"
    ) {
      axios
        .get(this.getApiUrl + "/exams/filter/getQuartersForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allQuarters = response.data.data.quarters;
          console.log(response.data.data.quarters);
          //  this.filter.quarters =[];
          this.filter.quarters.push(response.data.data.currntQuarterid);
        });
    }
    if (this.table.modal == "My Library" || this.table.modal == "Library") {
      axios
        .get(this.getApiUrl + "/exams/filter/getQuartersForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allQuarters = response.data.data.quarters;
          console.log(response.data.data.quarters);
          //  this.filter.quarters =[];
          this.filter.quarter = response.data.data.currntQuarterid;
        });
    }
    if (this.table.modal == "Student" && this.$route.query.unAssign) {
      this.filter.academic_periods.push(Number(this.$route.query.active_year));
      this.page = Number(1);
      this.filter.assign = "Not Assign";
    }

    if (this.table.modal == "Invoice") {
      axios
        .get(this.getApiUrl + "/homework/getGrade", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.invoiceGrades = response.data.data;
        });
    }
    if (this.table.modal == "Partner") {
      axios
        .get(this.getApiUrl + "/partners/getPartnerGroups", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.partnerGrops = response.data.data;
        });
    }
    if (this.table.modal == "Item") {
      axios
        .get(this.getApiUrl + "/itemsCategories?select=yes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.itemsCategory = response.data.data;
        });
    }
    if (this.table.modal == "Warehouse Item") {
      this.getWarehouseCategories();
    }
    // this.initializeDatePicker();

    if (this.table.modal == "Warehouse Transactin") {
      this.getWarehouses();
      this.getpartners();
      // this.IntializeDatePickerWareHouse();
    }
    if (this.table.modal == "Payment" || this.table.modal == "GeneralPayment") {
      this.getpartnersPayment();
      this.getAllCashiers();
    }

    // this.autoFocusModals();
    if (this.table.modal == "All Exam Results for parentDashboard") {
      this.getParentChilds();
    }
    if (this.table.modal == "Library") {
      this.getCategories();
    }
    if (
      this.table.modal == "All Exam Results for parentDashboard" ||
      this.table.modal == "All Exam Results"
    ) {
      this.options.itemsPerPage = 5;
    }

    if (this.table.modal == "assign student to class") {
      localStorage.activeTab = 0;
    } else if (this.table.modal == "Exam") {
      localStorage.activeTab = 3;
    }
    this.exportTitle = "Export All " + this.table.title;
    if (this.table.modal == "assign student to class") {
      if (this.deleteBtnVisible) {
        this.showSelect = true;
      }
    }

    if (this.table.modal == "Item" || this.table.modal == "Student") {
      if (this.exportBtnVisible) {
        this.showSelect = true;
      }
      axios
        .get(this.getApiUrl + "/students/getAllStudentFiles", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.files = response.data.data.files;
        });
    }
    if (
      this.table.modal != "User" &&
      this.table.modal != "Student" &&
      this.table.modal != "Fee Plan" &&
      this.table.modal != "Payment"
    ) {
      if (!this.editBtnVisible && !this.deleteBtnVisible) {
        this.table.headers = this.table.headers.filter(function (row) {
          return row.value != "action";
        });
      }
    } else if (this.table.modal == "User") {
      if (
        !this.editBtnVisible &&
        !this.deleteBtnVisible &&
        !this.changePasswordBtnVisible
      ) {
        this.table.headers = this.table.headers.filter(function (row) {
          return row.value != "action";
        });
      }
    } else if (this.table.modal == "Student") {
      if (
        !this.editBtnVisible &&
        !this.viewBtnVisible
        // !this.assignClassBtnVisible
      ) {
        this.table.headers = this.table.headers.filter(function (row) {
          return row.value != "action";
        });
      }
    } else if (this.table.modal == "Fee Plan") {
      if (
        !this.editBtnVisible &&
        !this.deleteBtnVisible &&
        !this.printBtnVisible
      ) {
        this.table.headers = this.table.headers.filter(function (row) {
          return row.value != "action";
        });
      }
    }

    $(".v-data-footer__select").remove();

    //this.getStudentExamsDashboard();
    if (this.$router.currentRoute.name != "Dashboard") {
      axios
        .get(this.getApiUrl + "/getGrades", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.grades = response.data.data.grades;
        });
      axios
        .get(this.getApiUrl + "/getCountries", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.nationalities = response.data.data;
        });

      axios
        .get(this.getApiUrl + "/getAllRoles", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.roles = response.data.data;
        });
    }

    if (this.table.modal == "Invoice") {
      axios
        .get(this.getApiUrl + "/school/academic_periods_all", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.years = response.data.data;
          this.years.forEach((item) => {
            if (item.is_active == 1) {
              this.filter.year_id = [item.id];
            }
          });
        });
    }

    // if (this.table.modal != "All Exam Results for parentDashboard") {
    //   let pageNumber = this.$router.currentRoute.params.pageNumber;
    //   this.getDataFromApi(pageNumber);
    // }
    // route on the first load
    if (
      this.$route.query.tab === undefined &&
      this.table.modal !== "All Exam Results for parentDashboard" &&
      this.table.modal !== "All Exam Results"
    ) {
      this.page = Number(this.$route.query.page);
      // let warehouse_item_id = "";
      // if (this.$route.query.item_id != undefined) {
      //   warehouse_item_id = "&item_id=" + this.$route.query.item_id;
      // }
      // this.$router.push(
      //   {
      //     path: "/" + this.table.path + "?page=" + this.page + warehouse_item_id
      //   },
      //   () => {}
      // );
      this.getDataFromApi(this.page);
    } else if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
      this.getDataFromApi(this.page);
    } else {
      this.page = 1;
      this.getDataFromApi(this.page);
    }
    if (this.table.modal == "Student") {
      this.getAllClasses();
      if (this.$route.query.global_status !== undefined) {
        if (this.$route.query.global_status == "new") {
          this.filter.status_student = ["new", "approved"];
        } else {
          this.filter.status_student = [this.$route.query.global_status];
        }
      }
    }
    // Books List index
    if (this.table.modal == "Books List") {
      this.getGrades();
      // this.getStudentBookList();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
@import "../styles/_modals.scss";

// should apply on all views
#wrapper {
  transition: all 0.4s ease 0s;
}

/* start new interface */
.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  overflow: hidden;
  padding: 15px 0px;
  .custom-header {
    margin-bottom: 0;
  }
}
.headerTable-div .item-category {
  width: 8.3%;
  float: left;
}

.center {
  margin-left: 30px !important;
}

img {
  height: 20px !important;
  margin: 3px !important;
}
img.category {
  height: 60px !important;
  border-radius: 50%;
  margin: 0 !important;
}
.select {
  margin-top: 20px !important;
}
.status {
  text-transform: capitalize;
}

.buttons-space {
  margin-bottom: 40px;
  margin-top: 30px;
}
/* end new interface */

.upgradeIcon {
  cursor: pointer;
  font-size: 1rem;
  color: #6d6d6d;
}
.bulkUpgradeIcon {
  cursor: pointer;
  font-size: 1.5rem;
  color: $main-color;
}
.exams-table .cart {
  padding-bottom: 0 !important;
}

.custom-bg {
  color: red;
  text-decoration: line-through;
}

.redRow {
  color: red;
}

.circle {
  width: 65px;
  height: 65px;
  //border: 3px solid #7297ff;
  // background-color: #f1f1f1;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
}
</style>
